.bs-canvas-overlay {
  opacity: 0;
  z-index: -1;
}

.bs-canvas-overlay.show {
  opacity: 0.85;
  z-index: 199;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bs-canvas-overlay, .bs-canvas {
  transition: all .4s ease-out;
  -webkit-transition: all .4s ease-out;
  -moz-transition: all .4s ease-out;
  -ms-transition: all .4s ease-out;
}

.bs-canvas {
  top: 0;
  z-index: 1110;
  overflow-x: hidden;
  overflow-y: auto;
  width: 330px;
}

.bs-canvas-left {
  left: 0;
  margin-left: -330px;
}

.bs-canvas-right {
  right: 0;
  margin-right: -330px;
}