.search {
  width: 100%;
  padding: 3px;
  border-radius: $border-radius;
  background: #fff;
  position: relative;
  margin-bottom: 25px;

  .btn {
    border: 0;
  }

  .form-control {
    flex: 1;
    border: 0;
    padding: 0.8125rem 0 0.8125rem 50px;
    font-size: 0.9375rem;
    outline: transparent;
    background: url(../../../images/search.svg) no-repeat 16px 50%;
    background-size: 22px 22px;

    &::-webkit-input-placeholder {
      font-weight: 700;
      color: #3A3A3A
    }

    &::-moz-placeholder {
      font-weight: 700;
      color: #3A3A3A
    }

    &:-ms-input-placeholder {
      font-weight: 700;
      color: #3A3A3A
    }

    &:-moz-placeholder {
      font-weight: 700;
      color: #3A3A3A
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.coming-soon {
  margin-top: 8px;
  padding: 4px 12px;
  border-radius: 5px;
  background-color: $coming-soon-red;
  color: white;
  align-self: flex-start;
}

.search-box {
  .search-box--backdrop {
    display: none;
    position: fixed;
    z-index: 12;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }

  form, .hint {
    z-index: 9;
  }


  &.focus {
    //width: 80vw;

    .search-inline--lg {
      //position: fixed;
      //top: 25vh;
      //margin-top: -25vh;
      //max-height: 60vh;
      //width: calc(100vw - 4rem);
      z-index: 801;
      top: -10vw;
      width: 100%;

      @include mq($from: lg) {
        left: -26vw;
      }

      @media (min-width: 1921px) {
        left: -500px;
        top: -350px;
      }

      &:after {
        display: none;
      }
    }

    form {
      width: 80vw;

      &:hover {
        width: 80vw!important;
      }

      @media (min-width: 1921px) {
        width: 1500px;

        &:hover {
          width: 1500px!important;
        }
      }
    }

    .hint {
      visibility: visible;
      width: 80vw;
      opacity: 1;
      z-index: 8;

      @media (min-width: 1921px) {
        width: 1500px;
      }
    }

    .search-box--backdrop {
      @include mq($from: sm) {
        display: block;
      }
    }

    form.search-inline.with-start:after {
      display: none;
    }

  }
}

#search-autocomplete {
  position: relative;
}

.search-mobile {
  .search-inline {
    top: unset;
    width: 100%;
  }
}