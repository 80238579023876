.news {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 60px 30px;
  justify-content: center;
  @include media-breakpoint-up(md) {
    //@include make-row();
    padding: 5rem 15px 2rem;
  }

  .card-text {
    flex-grow: 1;
  }
}

.news .card__image {
  position: relative;
  width: 100%;
  height: 245px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @include media-breakpoint-up(md) {
    height: 450px;
  }
}

.news .card--yellow .card__image::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, $yellow);
}

.news .card--yellow .card__time {
  position: relative;
  z-index: 9999;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-bottom: -10px;
  color: $body-color;
}

.news .card--yellow .card-body {
  @include media-breakpoint-up(lg) {
    padding: 2rem 4rem 4rem;
    justify-content: space-between;
  }
}

.news .card--gray .card__image::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 75%, #D5D5D5);
}

.news .card--gray .card__time {
  position: relative;
  z-index: 9999;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-bottom: -10px;
  color: $body-color;
}

.news {

  .card--gray {
    height: 100%;

    .card-body {
      @include media-breakpoint-up(lg) {
        padding: 2rem 4rem 4rem;
        justify-content: space-between;
      }
    }
  }

}

.container-news {
  max-width: 800px;
  margin: auto;
}

.news-list {
  img.card-img-top {

    @include media-breakpoint-up(sm) {
      height: 200px;
      object-fit: cover;
    }

  }
}

.news-detail {
  .card a:not(.btn) {
    color: $primary;
  }
}