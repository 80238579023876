.select-button {
  position: relative;
  top: -1px;
}

.contentPack-pagination {
  .pagination {
    justify-content: center;

    li.page-item {
      margin: 0 15px;

      .page-link {
        border-radius: 20px;
      }

      .page-link {
        color: #000;
        background-color: unset;
        border: none;
        font-weight: normal;

        &:focus {
          z-index: 3;
          color: unset;
          background-color: unset;
          outline: 0;
          box-shadow: unset;
        }

        &:hover {
          color: #fff;
          background-color: $blue;
          border-color: $blue;
        }
      }

      &.active .page-link {
        color: #fff;
        background-color: $blue;
        border-color: $blue;
        font-weight: bold;
      }

      &.disabled .page-link {
        color: #fff;
        pointer-events: none;
        background-color: #666;
        border-color: #dee2e6;
      }
    }

    .number-part {
      aspect-ratio: 1;
    }
  }
}