.list-params {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        margin: 1rem 0;
        padding: 5px 0 5px 42px;
    }

    a {
        text-decoration: none;
        color: $body-color;

        &:hover {
            color: #000;
            text-decoration: underline;
        }
    }

    .disabled {
        color: #8f8f8f;
    }

    .tippy-content {
        a {
            color: white;
            text-decoration: underline;
            &:hover {
                color: white;
            }
        }
    }
}

.param-grid {
    background: url(../../../images/param-grid.svg) no-repeat;
    background-size: 26px 26px;
}

.param-sheet {
    background: url(../../../images/param-sheet.svg) no-repeat;
    background-size: 26px 26px;
}

.param-overview,
.param-solution {
    background: url(../../../images/param-overview.svg) no-repeat;
    background-size: 26px 26px;
}

.param-sound {
    background: url(../../../images/param-sound.svg) no-repeat;
    background-size: 26px 26px;
}

.param-pdf {
    background: url(../../../images/param-pdf.svg) no-repeat;
    background-size: 26px 26px;
}

.param-image {
    background: url(../../../images/param-image.svg) no-repeat;
    background-size: 26px 26px;
}

.param-www {
    background: url(../../../images/param-www.svg) no-repeat;
    background-size: 26px 26px;
}