.user {
  .no-transition {
    transition: none !important;
  }
}

.user__sidebar {
  padding: 20px;

  h2 {
    color: #3a3a3a;
    font-size: 1.5rem;
    font-weight: 700;
    word-break: break-word;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    display: block;
    padding: 7px 0;

  }

  a {
    color: #707070;
    font-size: 1.063rem;
    text-decoration: none;
    font-weight: 400;

    &:hover {
      color: #3a3a3a;
    }
  }

  .active {
    color: #3a3a3a;
    font-weight: 700;
  }

  .mainAvatar {
    width: 10rem;
    height: 10rem;
  }

}

.mainAvatar {
  width: 115px;
  height: 115px;
  margin: 0 auto;
}

.mainAvatar {
  position: relative;
  z-index: 5;

  &__border {
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    border: 14px solid white;
    border-radius: 50%;
  }

  &__bg {
    width: 96%;
    height: 96%;
    border-radius: 50%;
    background: #ff78bc;
    z-index: -2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    overflow: hidden;
  }

  &__avatar {
    width: 70%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -36%);
  }
}

.user__avatar {
  width: 100%;
  height: auto;
  margin: 10px auto;
  position: relative;

  .edit {
    cursor: pointer;
    width: 57px;
    height: 57px;
    background: url(../../../images/user-edit.png);
    background-size: 100% 100%;
    position: absolute;
    right: 30px;
    bottom: -5px;
    //box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
    border-radius: 100%;
    z-index: 10;
  }

  .small-avatar {
    max-width: 7.188rem;
    height: auto;
    border-radius: 50%;
  }
}

.user__sidebar-mobile {
  .mainAvatar {
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 auto;

    &__border {
      border: 3px solid white;
    }
  }

  .header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .body .padding {
    padding: 20px;
  }

  .header__arrow {
    transition: all 300ms
  }

  .collapsed {
    .header__arrow {
      transform: rotate(0deg);
    }
  }

  .header__arrow {
    transform: rotate(180deg);
  }

  .dropdown-menu {
    position: unset;
    transform: translate(0, 0);
    left: unset;
    top: unset;
    width: 100%;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    display: block;
    padding: 7px 0;

  }

  a {
    color: #707070;
    font-size: 1.063rem;
    text-decoration: none;
    font-weight: 400;

    &:hover {
      color: #3a3a3a;
    }
  }

  .active {
    color: #3a3a3a;
    font-weight: 700;
  }
}

.user__dashboard {
  padding: 40px 30px;

  h2 {
    color: #3a3a3a;
    font-size: 1.375rem;
    font-weight: 700;
    letter-spacing: 0.028rem;
  }

  .graphs .bar-layout {
    flex: 1 1;
    display: flex;
    overflow: hidden;
  }

  .graphs .wide {
    height: 10rem;

    @include media-breakpoint-down(sm) {
      height: 70px;
    }

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    animation: rise forwards 500ms linear;
    animation-delay: 500ms;
    opacity: 0;
    overflow: hidden;

    &:before {
      content: '';
      flex: 0 0 auto;
      height: 100%;
    }

    .text-center {
      padding: 0 0.15rem;
    }
  }

  .graphs .wide p {
    color: #ffffff;
    margin-bottom: 14px;
    font-size: 1.375rem;
    font-weight: 700;
    letter-spacing: 0.028rem;
    line-height: 1.25rem;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      font-size: 0.75rem;
      letter-spacing: 0.015rem;
      margin: 0;
    }
  }

  .graphs .wide span {
    color: #ffffff;
    font-size: 1rem;
    font-style: italic;

    @include media-breakpoint-down(sm) {
      font-size: 0.75rem;
    }
  }

  .graphs .bar {
    width: 1.438rem;
    height: 160px;

    @include media-breakpoint-down(sm) {
      height: 70px;
    }

    margin: 0 2px;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;

    .anime {
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      animation: rise forwards 500ms linear;
      animation-delay: 500ms;
      opacity: 0;
    }
  }

  @keyframes rise {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }

    10% {
      transform: translateY(90%);
      //opacity: 0;
    }

    20% {
      transform: translateY(80%);
      //opacity: 0;
    }

    30% {
      transform: translateY(70%);
      //opacity: 0;
    }

    40% {
      transform: translateY(60%);
      //opacity: 0;
    }

    50% {
      transform: translateY(50%);
      //opacity: .5;
    }

    60% {
      transform: translateY(40%);
      //opacity: .6;
    }

    70% {
      transform: translateY(30%);
      //opacity: .7;
    }

    80% {
      transform: translateY(20%);
      //opacity: .8;
    }

    90% {
      transform: translateY(10%);
      //opacity: .9;
    }

    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  .graphs .subject {
    width: 100%;
  }

  .graphs .date {
    color: #3a3a3a;
    font-size: 1rem;
    font-weight: 700;
    margin-top: 8px;
  }

  .graphs h5 {
    text-align: center;
    color: #707070;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
  }

  .graphs__nav {
    margin: 20px 0;
    padding: 0;
    list-style: unset;

    .active {
      color: #3a3a3a;
      font-size: 1.063rem;
      font-weight: 700;

      span {
        border-bottom: 2px solid #3a3a3a;

      }
    }

    li {
      @include media-breakpoint-up(sm) {
        &:first-child {
          padding-left: 0;
          margin-left: -10px;
        }
      }

      min-width: 90px;
      display: inline-block;
      text-align: center;
      padding: 5px;
      color: #3a3a3a;
      font-size: 1.063rem;
      font-weight: 400;
      cursor: pointer;
    }

    li span {
      border-bottom: 2px solid transparent;

    }
  }

  table {
    width: 100%;
    color: #3a3a3a;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  table thead {
    border-bottom: 1px solid #3a3a3a;
    color: #3a3a3a;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  table thead tr {
    line-height: 40px;
  }

  .table th,
  td {
    border-top: 0;
    padding: 0;
  }

  table tr {
    line-height: 40px;
  }

  table tbody tr {
    line-height: 70px;
    border-bottom: 1px solid #daefef
  }

  table {
    vertical-align: middle;
  }

  table th {
    @include media-breakpoint-down(sm) {
      min-width: 240px;
    }
  }

  .user__tab-img {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(../../../images/table-subject.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 19px 28px;
    transform: translate(0, 35%);
  }

  .user__tab-title {
    color: #3a3a3a;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-decoration: underline;
  }

  .user__tab-subject {
    color: #3a3a3a;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: underline;
  }

  &.history {
    thead {
      tr {
        line-height: unset;

        td {
          padding: 0 0.5rem;
        }
      }
    }

    tbody {
      tr {
        line-height: unset;

        th {
          padding: 0 0.5rem;
          vertical-align: middle;

          .result {
            border: none;
            flex-flow: row nowrap;

            .result__content {
              display: flex;
              flex-direction: column;
              justify-content: center;

              .result__title {
                line-height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.user__dashboard {
  .count label {
    color: #3a3a3a;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .count select {
    padding: 8px 16px;
    box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
    border-radius: 0.625rem;
    border: 0.063rem solid #cee9e9;
    background-color: #ffffff;
    color: #3a3a3a;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.02rem;
  }
}

.user {
  &__filter {
    margin: 15px 0;
  }

  &__filter p {
    color: #3a3a3a;
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  svg {
    transition: all 300ms;
  }

  .show {
    svg {
      transform: rotate(180deg);
    }
  }

  &__filter .link {
    padding: 10px 20px;
    box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
    border-radius: 0.625rem;
    border: 0.063rem solid #cee9e9;
    background-color: #ffffff;
    color: #3a3a3a;
    font-size: 0.813rem;
    font-weight: 700;
    letter-spacing: 0.016rem;

    svg {
      margin-left: 15px;
    }
  }
}

.user {
  .modal-dialog {
    max-width: 37.5rem;
  }

  .modal-content {
    border-radius: 0.625rem;
    box-shadow: 1.563rem 3.125rem 1.875rem rgba(0, 0, 0, 0.16);
  }

  .modal-body {
    padding: 50px;
  }

  .modal {
    h2 {
      color: #3a3a3a;
      font-size: 1.5rem;
      font-weight: 700;
    }

    p {
      margin-top: 30px;
      color: #3a3a3a;
      font-size: 1rem;
      letter-spacing: 0.02rem;
      line-height: 1.5rem;
      font-weight: 400;
    }

    .btn-danger {
      color: white;
    }

    .btn-dark {
      background: #d5d5d5;
      border-color: #d5d5d5;
      color: #3a3a3a;

      &:hover {
        background: darken(#d5d5d5, 5%);
        border-color: darken(#d5d5d5, 5%);
        ;

      }
    }
  }

  .modal .cta {
    margin-top: 50px;
  }

  .modal .btn {
    max-width: 11.125rem;
    width: 100%;
  }
}