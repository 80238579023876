.subjects {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 60px 30px;
  justify-content: center;
  @include media-breakpoint-up(md) {
    @include make-row();
    padding: 0 15px 7rem 15px;
  }
}
.subjects__content {
  background-color: #EBEBEB;
  border-radius: 20px;
  padding: 2rem;
  @include media-breakpoint-up(md) {
    padding: 8rem;
  }
}