.search-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $yellow;
  @include gradient-bg(rgba(255,193,7, .85));
  height: calc(100vh - 98px);
  width: 100%;
  position: absolute;
  top: 0;
}

.search-modal .search-inline {
  width: 100%;
}

.search-inline {

  //position: relative;

  .hint {
    width: 100%;
    min-height: 350px;
    visibility: hidden;
    margin: -3.75rem auto 0;
    padding: 6.5rem 2rem 2rem;
    border-radius: 30px;
    @include gradient-bg(#eaeaea);
    box-shadow: 0 15px 35px #393939;
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: ease;
    transition-duration: 500ms;
    transition-delay: 500ms;
  }

  .filter__hint__circle {
    @include gradient-bg(#FFB300);
  }
}

