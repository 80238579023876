@use "sass:math";

@mixin container($width: $container-width) {
    max-width: $width;
}

@mixin subcontainer($size) {
    max-width: math.div($size, $grid-columns) * $container-width;
    margin-left: math.div($grid-gutter-width, 2);
    margin-right: math.div($grid-gutter-width, 2);
}

@if (type-of($container-width) == 'number' and index('px', unit($container-width)) != null) {
    @for $i from 1 through $grid-columns {
        .container-#{$i} {
            @include subcontainer($i);
        }
    }
}
