.activity {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    transition: width ease-in-out .3s;

    @include mq($from: md) {
        &.show-offcanvas {
            width: calc(100% - 500px);

            .filter__recommendation {
                display: none;
            }
        }
    }

    .v-center {
        @include mq($from: lg) {
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
    }

    #activity-iframe {
        //TODO: zakomentováno v rámci úkolu 17985 - pokud nebude zaznamenán nějaký problém, tak lze smazat
        //min-height: 500px;
        flex-grow: 1;
        position: relative;

        a {
            position: relative;
            top: 1rem;
        }

        .iframe-container {
            height: 100%;
        }

        iframe {
            //position: absolute;
            //top: 0;
            //bottom: 0;
            //left: 0;
            //right: 0;
        }
    }

    #activity-overlay,
    #activity-multimedia {
        height: inherit;
        //TODO: zakomentováno v rámci úkolu 17985 - pokud nebude zaznamenán nějaký problém, tak lze smazat
        //min-height: 500px;
        flex-grow: 1;
    }

    #activity-overlay {
        display: flex;
        justify-content: center;

        color: white;

        h1 {
            position: relative;
            padding-bottom: 1rem;
            font-size: 2.5rem;

            &:after {
                content: "";
                width: 120px;
                position: absolute;
                left: 50%;
                bottom: 0;
                height: 3px;
                margin-left: -60px;
                background: #A43979;
            }
        }

        .activity__info {
            width: 900px;
            padding: 7em 2em 0 2em;
        }

        .activity__params {
            font-size: 0.8125rem;

            ul,
            li {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            li {
                padding: 3px 0 3px 28px;
                margin: 0.25rem 0.75rem;
                background: url(../../../images/stopwatch.svg) no-repeat 0 0;

                &.difficulty-A {
                    background: url(../../../images/level1.svg) no-repeat 0 0;
                    background-size: 22px;
                    background-position: left;
                }

                &.difficulty-B {
                    background: url(../../../images/level2.svg) no-repeat 0 0;
                    background-size: 22px;
                    background-position: left;
                }

                &.difficulty-C {
                    background: url(../../../images/level3.svg) no-repeat 0 0;
                    background-size: 22px;
                    background-position: left;
                }

                &.with-evaluation {
                    background: url(../../../images/check-2.svg) no-repeat 0 0;
                    background-size: 22px;
                    background-position: left;
                }
            }
        }

        .activity__publication-banner {
            display: flex;

            position: relative;

            flex-direction: column;
            justify-content: center;

            flex: 1 1 calc(100%/6);

            height: 100%;

            padding: 0 6em;

            align-self: center;

            text-align: left;

            .book-image {
                display: inline-block;

                max-width: 185px;

                margin-bottom: 40px;
            }

            .book-title {
                font-size: 22px;
                font-weight: bold;

                margin-bottom: 20px;
            }

            .book-claim {
                margin-bottom: 30px;
            }

            .marketing-banner__background {
                z-index: 0;

                position: absolute;

                left: 0;
            }
        }
    }

    #activity-multimedia {
        padding-bottom: 12em;

        h1 {
            position: relative;
            padding-bottom: 1rem;
            font-size: 2.5rem;
        }

        h2 {
            margin-bottom: 1em;
        }

        .activity__info {
            padding-top: 4em;
            max-width: 600px;
            margin: 0 auto;
        }

        .activity__params {
            font-size: 0.8125rem;

            ul,
            li {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            li {
                padding: 3px 0 3px 28px;
                margin: 0.25rem 0.75rem;
                background: url(../../../images/stopwatch-dark.svg) no-repeat 0 0;
            }
        }

        .activity__content {
            max-width: 600px;

            margin: 0 auto;
            padding: 44px 0;

            background: white;

            border-radius: 14px;

            text-align: left;
            font-size: 16px;

            box-shadow: 25px 50px 30px 0 rgba(0, 0, 0, 0.16);

            &.with-publication {
                padding-bottom: 0;
            }

            &.model3d {
                max-width: 960px;
            }

            &.geogebra {
                max-width: 100vw;
                width: 100%;

                @include mq($from: lg) {
                    max-width: 800px;
                    width: fit-content;
                }
            }

            .ggb-container {
                max-width: 100%;
            }

            .pdf-container {
                .fullscreen-bar {
                    display: none;
                }

                &.fullscreen {
                    width: 100%;
                    height: 100%;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 99999;

                    .fullscreen-bar {
                        display: flex;

                        align-items: center;
                        justify-content: space-between;

                        position: fixed;

                        bottom: 0;

                        width: 100%;
                        height: 70px;

                        padding: 0 24px;

                        background-color: #bcbec0;

                        .minimize-pdf {
                            display: inline-block;

                            width: 36px;
                            height: 36px;

                            background: url(../../../images/icon_minimize.svg) no-repeat center;

                            font-size: 0;

                            line-height: 48px;
                        }
                    }

                    iframe {
                        position: absolute;

                        height: calc(100% - 68px) !important;

                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 50px;
                    }
                }
            }

            .column,
            &>h2 {
                padding: 0 42px;
            }

            a.media__link {
                float: right;
                margin: 22px 40px 0 0;

                &.maximize-pdf {
                    margin: 4px 24px 0 0;
                }
            }

            a.attachment__link {
                display: inline-block;

                width: 100%;

                padding: 0.6666em 0 0.8888em 0;

                font-size: 1.125em;

                border-top: 1px #CEE9E9 solid;

                &::before {
                    content: '';

                    width: 22px;
                    height: 22px;

                    background: url(../../../images/file_document.svg) no-repeat center;

                    display: inline-block;
                    position: relative;

                    top: 5px;
                    margin-right: 6px;
                }
            }

            .segment-link {
                position: relative;
                min-height: 80px;

                a {
                    display: block;

                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: black;
                        opacity: 0.4;
                    }

                    .btn {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }

    .filter__select {
        display: flex;
        padding: 0 20px;
        flex-basis: calc(100% / 2);
        max-width: calc(100% / 2);
        justify-content: center;

        @include mq($from: xl) {
            justify-content: flex-start;
        }

        a.back {
            width: auto;
            height: auto;

            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1rem;

            @include mq($from: md) {
                font-size: 1.125rem;
            }

            @include mq($from: xl) {
                flex-direction: row;
                align-items: center;
            }

            font-weight: bold;
            text-decoration: none;
            color: $body-color;

            svg {
                width: 32px;

                margin-right: 1rem;
            }

            span {
                position: relative;
                top: 5px;

                @include mq($from: xl) {
                    top: 1px;
                }
            }
        }

        &.nextActivity {
            background-color: #F0C200;

            a.back {
                svg {
                    margin-right: 0;
                    margin-left: 1rem;
                }
            }
        }

        @include mq($from: xl) {
            flex-basis: auto;
            max-width: none;
        }
    }

    .filter__search {
        flex-basis: calc(100% / 2);
        max-width: calc(100% / 2);

        @include mq($from: xl) {
            flex-basis: 250px;
            max-width: 100%;
        }

        .search-inline {
            justify-content: center;
        }
    }

    .filter--search {
        .filter__options {
            display: none;
        }

        .filter__search {
            max-width: 75%;
            flex: auto;
            transition: max-width .5s;
        }
    }

    .row-detail {
        @include media-breakpoint-up(md) {
            height: 100%;
        }
    }

    video.circled {
        width: 75%;
        border-radius: 50%;

        @include media-breakpoint-down(sm) {
            width: 90%;
            max-width: 300px;
            margin-top: 25px;
        }
    }

    .filter__recommendation {
        flex-basis: 280px;
        flex-grow: 0;
        justify-content: left;
        background-color: #FFCE00;
        position: fixed;
        bottom: 110px;
        right: 20px;

        @include mq($from: xl) {
            position: relative;
            bottom: 0;
            right: 0;
        }

        .badge {
            font-size: 17px;
            z-index: 2;
        }

        .filter__label {
            padding-top: 39px;
            width: auto;
            position: relative;
            text-indent: -9999px;

            &:before {
                left: auto;
                right: 0;
                top: 50%;
                width: 50px;
                height: 50px;
                margin-top: -25px;
            }

            @include mq($from: xl) {
                display: block;
                text-indent: 0;
            }
        }
    }
}

.activity-offcanvas {
    &.offcanvas {
        width: 100%!important;
    }
}

@include mq($from: md) {
    .activity-offcanvas {
        &.offcanvas {
            max-height: 100%;
            width: 500px!important;
        }
    }
}