.parsley-errors-list {
    @include mq($from: sm) {
        width: 65%;
    }
}
.parsley-errors-list.filled, .errors-list
{
    margin: 0;
    padding: 10px;
    list-style: none;
    background-color: #c00;
    color: #fff;
    border-radius: 4px;
    font-size:13px;
    font-weight: 400;
}
.parsley-errors-list.filled,
.errors-list {
    display: block;
    width: 100%;
    position: relative;
    margin:10px 0 5px 0;
    &:before {
        content:"";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent #c00 transparent;
        position: absolute;
        left:10px;
        top:-4px;
    }
}
.errors-list {
    background-color: #c00;
    &:before {
        border-color: transparent transparent #c00 transparent;
    }
}