.list-2 {
    display: flex;
    flex-wrap: wrap;

    li {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: $list-padding;
    }
}

.list-3 {
    @include mq($from: md) {
        display: flex;
        flex-wrap: wrap;

        li {
            flex: 0 0 33.333%;
            max-width: 33.333%;
            padding-right: $list-padding;
        }
    }
}

.list-4 {
    @include mq($from: md) {
        display: flex;
        flex-wrap: wrap;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: $list-padding;
        }
    }

    @include mq($from: lg) {
        li {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
}

.list-5 {
    @include mq($from: md) {
        display: flex;
        flex-wrap: wrap;

        li {
            flex: 0 0 33.333%;
            max-width: 33.333%;
            padding-right: $list-padding;
        }
    }

    @include mq($from: lg) {
        li {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}

.list-6 {
    @include mq($from: md) {
        display: flex;
        flex-wrap: wrap;

        li {
            flex: 0 0 33.333%;
            max-width: 33.333%;
            padding-right: $list-padding;
        }
    }

    @include mq($from: lg) {
        li {
            flex: 0 0 16.666%;
            max-width: 16.666%;
        }
    }
}