.filter {
  position: relative;

  background-color: #e0b500;

  &.sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 4;
  }
}

.filter--search {
  .filter__options {
    display: none;
  }

  .filter__search {
    max-width: 100%;
    flex: auto;
    transition: max-width .5s;
  }
}

.filter__in {
  margin: auto;
  height: 127px;

  @include mq($from: xl) {
    min-height: 100px;
    height: fit-content;
  }
}

.filter__select {
  flex: 0 0 calc(100% / 3);
  max-width: calc(100% / 3);

  @include mq($from: xl) {
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    align-items: center;
  }

  @include mq($from: xl) {
    flex-wrap: nowrap;
    flex: 1 0 0%;
    flex-basis: auto;
    max-width: calc(100vw - 480px);
  }
}

.filter__label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  padding: 1.5rem 20px;
  text-decoration: none;
  color: $body-color;
  line-height: 1.2;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  @include mq($from: sm) {
    font-size: 1.125rem;
  }

  @include mq($from: xl) {
    pointer-events: none;
    width: 14rem;
    min-width: 14rem;
    padding: 2rem 20px;
  }
}

.filter__options {
  display: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 37%;

    &:first-child {
      max-width: 25%;
    }
  }

  @include mq($from: xl) {
    display: flex;
    flex-grow: 1;
  }
}

.filter__option {
  display: block;
  position: relative;
  margin: 5px 10px 5px 0;
  padding: 9px 45px 9px 15px;
  list-style: none;
  background: #fff;
  font-size: 0.9375rem;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  color: $body-color;

  &.disabled {
    opacity: 0.25;
    cursor: default;
    pointer-events: none;

    &:hover {
      color: inherit;
    }
  }

  &:hover {
    color: #B4C642;
    text-decoration: none;
  }

  li:last-child & {
    margin-right: 0;
  }

  span {
    display: block;
    max-width: 7em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @include mq($from: xl) {
    max-width: none;

    span {
      max-width: none;
    }
  }
}

.filter__main {
  .filter__in {
    display: flex;
  }

  .filter__select {
    position: relative;
    background: #e0b500;
    max-height: 100%;
    overflow: hidden;

    .filter__label {
      padding: 0;
      padding-top: 75px;
      text-align: center;

      @include mq($from: xl) {
        text-align: left;
        padding-left: 65px;
        padding-top: 0;
      }

      &:before {
        content: "";
        width: 34px;
        height: 30px;
        background: url(../../../images/filter-ico.svg) no-repeat;
        background-size: contain;
        position: absolute;
        margin-top: -15px;

        left: calc(50% - 15px);
        top: 39%;

        @include mq($from: xl) {
          left: 20px;
          top: 50%;
        }
      }
    }
  }

  .filter__options {
    li:last-child:after {
      content: normal;
      display: none;
    }
  }

  .filter__option {
    &.disabled {
      &:hover:before {
        background: url(../../../images/filter-option.svg) no-repeat;
      }
    }

    &:before {
      content: "";
      width: 12px;
      height: 8px;
      position: absolute;
      right: 14px;
      top: 50%;
      margin-top: -4px;
      background: url(../../../images/filter-option.svg) no-repeat;
      background-size: contain;
      transition: transform .3s;
    }

    &:hover:before {
      background: url(../../../images/filter-option-hover.svg) no-repeat;
    }

    &[aria-expanded="true"] {
      z-index: 10;

      &:before {
        transform: rotate(180deg) translateY(1px);
      }

      @include mq($from: xl) {
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent #fff transparent;
          position: absolute;
          left: 50%;
          bottom: -26px;
          margin-left: -5px;
        }
      }
    }
  }
}

.filter__sub {
  position: relative;
  z-index: 2;
  padding: 1rem 0;
  background-color: #fff;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -100px;
    height: 0;
    width: 100%;
    background: #000;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s;
  }

  .filter__in {
    position: relative;
    z-index: 2;
    background: #fff;
  }

  .filter__select {
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  .filter__label {
    padding-top: 10px;
    padding-left: 65px;
    font-size: 1.25rem;
  }

  .filter__option {
    border: 2px solid #B4C642;
    margin: 5px 10px 5px 0;
    padding: 9px;
  }

  &.show {
    &:before {
      visibility: visible;
      opacity: 0.25;
      height: 100px;
    }
  }
}

.filter__search {
  position: relative;
  //z-index: 10;
  background: #d1a900;
  width: 100%;
  transition: flex-basis .5s ease-out;
  max-height: 100%;

  flex: 0 0 calc(100% / 3);
  max-width: calc(100% / 3);

  @include mq($from: xl) {
    display: flex;
    align-items: center;
    flex-basis: 200px;
    max-width: 100%;
  }


  &.full-width {
    flex-basis: 100%;

    @include mq($from: xl) {
      flex-basis: calc(100% - 280px);
      max-width: calc(100% - 280px);
    }
  }

  @include mq($from: xl) {
    margin: 0;
  }

  .filter__label {
    cursor: pointer;
    pointer-events: initial;
    padding: 0;
    padding-top: 75px;
    text-align: center;

    @include mq($from: xl) {
      text-align: left;
      padding-left: 65px;
      padding-top: 0;
      width: 10rem;
      min-width: 10rem;
    }

    &:before {
      content: "";
      width: 30px;
      height: 30px;
      background: url(../../../images/search-dark.svg) no-repeat;
      background-size: contain;
      position: absolute;
      margin-top: -15px;

      left: calc(50% - 15px);
      top: 39%;

      @include mq($from: xl) {
        left: 20px;
        top: 50%;
      }
    }

    @include mq($from: xl) {
      top: 30%;
    }

    &:hover:before {
      background-image: url(../../../images/search-white.svg);
    }
  }

  form {
    display: none !important;
    position: relative;
    z-index: 2;
    flex-wrap: nowrap;
    width: 100%;
    padding: 3px;
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  }

  .btn {
    padding: 0.5rem;
    text-transform: none;
    min-width: 80px;
  }

  @include mq($from: xl) {
    padding: 0 20px;

    form {
      display: flex !important;;
    }
  }

  .search-inline {
    min-height: 100%;
  }

}

.filter__recommendation {
  display: flex;
  justify-content: flex-end;

  flex: 0 0 calc(100% / 3);
  max-width: calc(100% / 3);
  background-color: #FFCE00;

  @include mq($from: xl) {
    flex-grow: 1;
    background-color: #e0b500;
    flex-basis: auto;
    max-width: 100%;
    min-width: 280px;
  }

  .badge {
    z-index: 2;
  }

  .filter__label {
    cursor: pointer;
    pointer-events: initial;
    padding: 0;
    padding-top: 75px;
    text-align: center;
    width: 100%;

    @include mq($from: xl) {
      display: none;
    }

    .viky-animation {
      position: absolute;
      margin-top: -15px;

      left: calc(50% - 15px);
      top: 39%;
    }

    .big-animation {
      text-indent: 0;

      .viky-animation {
        left: auto;
        right: -32px;
        width: 58px;
        height: 50px;

        & > div {
          svg {
            g {
              path[fill~="rgb(246,197,0)"] {
                fill: #FFCE00;
              }
            }
          }
        }
      }
    }
  }

  .card {
    display: none;
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: black;
    max-width: 220px;
    cursor: pointer;
    z-index: 4;

    @include mq($from: xl) {
      display: flex;
    }

    .card-body {
      padding: 1rem;
    }

    .viky-face {
      height: 90px;
      background-size: 326%;
      background-position-x: center;
      background-position-y: 37%;
      border-radius: 20px 20px 0 0;
      transition-property: height;
      transition-duration: .5s;
      transition-timing-function: ease-out;
      position: relative;
      overflow: hidden;

      & > div {
        position: absolute;
        height: 720px !important;
        width: 100% !important;;
        top: -166px;
        transition-property: height, top;
        transition-duration: .5s;
        transition-timing-function: ease-out;
      }

      strong {
        opacity: 0;
        position: relative;
        top: 15px;
      }
    }

    &:hover, &.active {
      .viky-face {
        height: 300px;

        & > div {
          height: 300px !important;
          top: 20px;
        }
      }

      strong {
        opacity: 1;
      }
    }

    .badge {
      font-size: 17px;
      z-index: 2;
    }
  }
}

.form-control--search {
  padding: 0.5rem 0 0.5rem 50px;
  width: 100% !important;
  height: auto;
  border: 0;
  background: rgba(0, 0, 0, 0) url(../../../images/search.svg) no-repeat 16px 50%;
  background-size: 22px 22px;

  &:focus {
    background-color: rgba(0, 0, 0, 0);

    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    font-style: italic;
    color: #3A3A3A
  }

  &::-moz-placeholder {
    font-style: italic;
    color: #3A3A3A;
  }

  &:-ms-input-placeholder {
    font-style: italic;
    color: #3A3A3A;
  }

  &:-moz-placeholder {
    font-style: italic;
    color: #3A3A3A;
  }

  &.hide-caret {
    caret-color: transparent;
  }

  &.loading {
    background-image: url(../../../images/spinner.svg) !important;
  }
}

.filter__hint {
  display: none;
  position: absolute;
  z-index: 10;
  left: 6px;
  right: 6px;
  top: -250px;
  background: #fff;
  height: 320px;
  padding: 40px 15px 30px 15px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin-top: -25px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 1.125rem;
  }
}

.filter__hint__search {
  position: relative;
  max-width: 420px;
  padding-left: 70px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    width: 50px;
    height: 50px;
    background: url(../../../images/search.svg) no-repeat;
  }
}

.filter--search-delay {
  .filter__hint {
    display: block;
  }

  form {
    z-index: 11;
  }
}

.filter__hint__circle {
  margin: auto;
  width: 210px;
  height: 210px;
  border-radius: 105px;
  background: #FFB300;
  padding: 30px;
  text-align: center;
  border: 1px solid #fff;
}

.filter__lightbox {
  width: 100%;
  height: 100%;
  padding: 0 40px 40px;
  background-color: #B4C642;
  background-image: url(../../../images/pixel-left.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  max-width: 100vw;
  margin: 0;

  .close {
    @extend .btn;
    @extend .btn-link;
    font-size: 3rem;
    font-weight: 500;
    position: absolute;
    top: -20px;
    right: -40px;
    color: $dark;
  }

  .modal-header {
    padding: 40px 40px 0;
    border: 0;
  }

  .modal-dialog {
    padding: 10px;
  }

  .modal-content {
    border: none;
  }

  select,
  option {
    font-weight: 700;
    font-family: $font-family-base;
    text-transform: uppercase;
  }

  label {
    font-weight: 700;
  }

  select {
    max-width: 240px;
    border: 0;
    height: auto;
    padding: 0.75rem;
    background: #fff url(../../../images/select.svg) no-repeat right;
    background-size: 26px 8px;
  }

  option {
    font-weight: 700;
  }
}

.filter__lightbox-search {
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: #EBCF00;
  background-image: url(../../../images/pixel-left.png);
  background-position: 0 0;
  background-repeat: no-repeat;

  .form-control--search {
    margin-bottom: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 50px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  }

  .filter__hint__circle {
    margin-top: 6rem;
    margin-bottom: 2rem;
  }
}


.filter__tip {
  position: absolute;
  left: 30px;
  bottom: 100%;
  width: 270px;
  height: 200px;
  padding: 0 35px 0 90px;
  z-index: 2;
  background: url(../../../images/tip.png) no-repeat;
  text-align: center;
  line-height: 1.4;
  font-size: 0.875rem;
  overflow: hidden;
  transition: height 1s cubic-bezier(.02, .01, .47, 1);

  h3 {
    margin: 30px 0 0 0;
    color: $frog;
    text-transform: uppercase;
  }

  p {
    margin: 0.5rem 0 0 0;
  }

  &.hidden {
    height: 0;
  }
}

#filter-search-lightbox {
  .btn-search {
    margin-left: 0;
  }

  .form-control--search {
    background-color: white;
  }

  .search-mobile {
    .form-control--search {
      background-color: rgba(255, 255, 255, 0);
    }
  }
}

@include mq($until: xl) {
  .filter__in.filter__in__no_rec {
    .filter__search {
      flex: 0 0 calc(100% / 2);
      max-width: calc(100% / 2);
    }

    .filter__select {
      flex: 0 0 calc(100% / 2);
      max-width: calc(100% / 2);
    }
  }
}