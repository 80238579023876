.search-expand {
    .form-inline {
        display: block;
    }

    input[type=text],
    input[type=search] {
        float: right;
        width: 0;
        padding-right: 30px;
        border-bottom: 1px solid transparent;
        background: $primary url(../../../images/search.svg) no-repeat right;
        color: #fff;
        background-size: 40px;
        cursor: pointer;
        transition: width 0.4s ease;

        &:focus {
            outline: none;
            width: 240px;
            border-bottom: 1px solid $primary;
            cursor: text;
        }

        &::-ms-clear {
            width: 0;
            height: 0;
        }
    }

    button {
        display: none;
    }
}