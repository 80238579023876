.offcanvas--publication {
  width: 600px;
  max-width: 100vw;
  overflow-y: auto;

  .btn-close {
    position: relative;
    background: none;
    opacity: 1;

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-width: 15px;
      border-style: solid;
      border-color: white;
      border-radius: 50%;
      transition: border-color .5s ease-out;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.414' height='7.414' viewBox='0 0 7.414 7.414'%3E%3Cg transform='translate(522.721 2280.845)'%3E%3Cline x2='6' y2='6' transform='translate(-522.013 -2280.138)' fill='none' stroke='%23000' stroke-linecap='round' stroke-width='1'/%3E%3Cline x2='6' y2='6' transform='translate(-516.013 -2280.138) rotate(90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E");
      background-position: center;
      background-size: 15px;
      background-repeat: no-repeat;
    }

    &:hover {
      &:before {
        border-color: black;
      }

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.414' height='7.414' viewBox='0 0 7.414 7.414'%3E%3Cg transform='translate(522.721 2280.845)'%3E%3Cline x2='6' y2='6' transform='translate(-522.013 -2280.138)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1'/%3E%3Cline x2='6' y2='6' transform='translate(-516.013 -2280.138) rotate(90)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }

  .offcanvas-body {
    min-height: fit-content;
    overflow-y: visible;
  }
}