.setting {
  &__avatar {
    .mainAvatar {
      width: 10rem;
      height: 10rem;
    }
  }
  &__avatar .selects {
    input[type="radio"] {
      display: none;
    }
    label {
      cursor: pointer;
      border: 4px solid transparent;
    }
    input[type="radio"]:checked+label {
      border: 4px solid #c67822;
    }

    img {
      width: 6.5rem;
    }
  }
  .big-avatar {
    max-width: 12.5rem;
    height: auto;
  }
  &__other {
    p {
      margin: 30px 0 ;
      color: #3a3a3a;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
    }

    strong {
      font-weight: 700;
    }
  }
}

#student_profile_edit_newsletters {
  padding-left: 2rem;
}