.icon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  &.icon-menu-badge {
    position: relative;
    top: -5px!important;
  }
}

$icons: (
        "sm": .5rem,
        "md": .75rem,
        "lg": 1rem,
        "xl": 1.25rem
);

@each $name, $size in $icons {
  .icon-#{$name} {
    &.icon-clock {
      background-image: url('../../../user/icons/clock.svg');
      $width: 16px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-clock-w {
      background-image: url('../../../user/icons/clock-w.svg');
      $width: 16px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-mail {
      background-image: url('../../../user/icons/mail.svg');
      $width: 16px;
      $height: 11px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-times {
      background-image: url('../../../user/icons/times.svg');
      $width: 7.414px;
      $height: 7.414px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-check {
      background-image: url('../../../user/icons/check.svg');
      $width: 9.396px;
      $height: 7.313px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-sand-clock {
      background-image: url('../../../user/icons/sand-clock.svg');
      $width: 23px;
      $height: 23px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-check-2 {
      background-image: url('../../../user/icons/check-2.svg');
      $width: 14.121px;
      $height: 10.121px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-search {
      background-image: url('../../../user/icons/search.svg');
      $width: 16px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-check-2-bl {
      background-image: url('../../../user/icons/check-2-bl.svg');
      $width: 14.121px;
      $height: 10.121px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-next {
      background-image: url('../../../user/icons/next.svg');
      $width: 14px;
      $height: 16.661px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-arrow-r {
      background-image: url('../../../user/icons/arrow-r.svg');
      $width: 17.419px;
      $height: 15.353px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-arrow-r-bl {
      background-image: url('../../../user/icons/arrow-r-bl.svg');
      $width: 17.419px;
      $height: 15.353px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-arrow-l {
      background-image: url('../../../user/icons/arrow-l.svg');
      $width: 8.045px;
      $height: 16.115px;
      width: $size / 2;
      height: $height / $width * $size;
    }

    &.icon-arrow-l-mh {
      background-image: url('../../../user/icons/arrow-l.svg');
      $width: 8.045px;
      $height: 8.045px;
      width: $size / 2;
      height: $height / $width * $size;
    }

    &.icon-slider-prev-dark {
      background-image: url('../../../user/icons/slider-prev-dark.svg');
      $width: 52px;
      $height: 52px;
      width: $size * 2;
      height: $height / $width * $size * 2;
    }

    &.icon-slider-next-dark {
      background-image: url('../../../user/icons/slider-next-dark.svg');
      $width: 52px;
      $height: 52px;
      width: $size * 2;
      height: $height / $width * $size * 2;
    }

    &.icon-arrow-t-r {
      background-image: url('../../../user/icons/arrow-t-r.svg');
      $width: 12.728px;
      $height: 12.728px;
      width: $size / 2;
      height: $height / $width * $size;
    }

    &.icon-arrow-b-r {
      background-image: url('../../../user/icons/arrow-b-r.svg');
      $width: 12.728px;
      $height: 12.728px;
      width: $size / 2;
      height: $height / $width * $size;
    }

    &.icon-blocks-plus {
      background-image: url('../../../user/icons/blocks-plus.svg');
      $width: 12px;
      $height: 12px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-bubble {
      background-image: url('../../../user/icons/bubble.svg');
      $width: 14px;
      $height: 13.119px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-timer {
      background-image: url('../../../user/icons/timer.svg');
      $width: 13px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-timer-wh {
      background-image: url('../../../user/icons/timer-wh.svg');
      $width: 13px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-cube {
      background-image: url('../../../user/icons/cube.svg');
      $width: 16px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-cube-wh {
      background-image: url('../../../user/icons/cube-wh.svg');
      $width: 16px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-level-1 {
      background-image: url('../../../user/icons/level-1.svg');
      $width: 31px;
      $height: 12px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-level-2 {
      background-image: url('../../../user/icons/level-2.svg');
      $width: 31px;
      $height: 12px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-level-3 {
      background-image: url('../../../user/icons/level-3.svg');
      $width: 31px;
      $height: 12px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-doors-plus {
      background-image: url('../../../user/icons/doors-plus.svg');
      $width: 14px;
      $height: 15px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-pencil {
      background-image: url('../../../user/icons/pencil.svg');
      $width: 16px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-plus {
      background-image: url('../../../user/icons/plus.svg');
      $width: 12px;
      $height: 12px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-plus-bl {
      background-image: url('../../../user/icons/plus-bl.svg');
      $width: 12px;
      $height: 12px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-revert {
      background-image: url('../../../user/icons/revert.svg');
      $width: 14px;
      $height: 14px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-times-b {
      background-image: url('../../../user/icons/times-b.svg');
      $width: 9.899px;
      $height: 9.899px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-user {
      background-image: url('../../../user/icons/user.svg');
      $width: 12.548px;
      $height: 15.927px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-user-bl {
      background-image: url('../../../user/icons/user-bl.svg');
      $width: 12.548px;
      $height: 15.927px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-basket {
      background-image: url('../../../frontend/images-cp/icons/cart.svg');
      $width: 18.4px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-exercise {
      background-image: url('../../../frontend/images-cp/icons/exercise.svg');
      $width: 22px;
      $height: 22px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-blocks {
      background-image: url('../../../user/icons/blocks.svg');
      $width: 16px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-blocks-bl {
      background-image: url('../../../user/icons/blocks-bl.svg');
      $width: 16px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-doors {
      background-image: url('../../../user/icons/doors.svg');
      $width: 11px;
      $height: 14px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-doors-bl {
      background-image: url('../../../user/icons/doors-bl.svg');
      $width: 11px;
      $height: 14px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-dashboard {
      background-image: url('../../../user/icons/dashboard.svg');
      $width: 14px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-message {
      background-image: url('../../../user/icons/message.svg');
      $width: 15.993px;
      $height: 15.993px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-settings {
      background-image: url('../../../user/icons/settings.svg');
      $width: 15.435px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-toggler {
      background-image: url('../../../user/icons/toggler.svg');
      $width: 14px;
      $height: 20px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-arrow-t {
      background-image: url('../../../user/icons/arrow-t.svg');
      $width: 11.448px;
      $height: 6.045px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-arrow-b {
      background-image: url('../../../user/icons/arrow-b.svg');
      $width: 11.448px;
      $height: 6.045px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-watch {
      background-image: url('../../../user/icons/watch.svg');
      $width: 18.607px;
      $height: 11.629px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-2 {
      background-image: url('../../../user/icons/2.svg');
      $width: 12px;
      $height: 14px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-3 {
      background-image: url('../../../user/icons/3.svg');
      $width: 12px;
      $height: 11px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-4 {
      background-image: url('../../../user/icons/4.svg');
      $width: 12px;
      $height: 9px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-5 {
      background-image: url('../../../user/icons/5.svg');
      $width: 12px;
      $height: 9px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-dots-y {
      background-image: url('../../../user/icons/dots-y.svg');
      $width: 3px;
      $height: 13px;
      background-size: $width $height;
      width: $width * 3;
      height: $height / $width * $size;
    }

    &.icon-dots-y-w {
      background-image: url('../../../user/icons/dots-y-w.svg');
      $width: 3px;
      $height: 13px;
      background-size: $width $height;
      width: $width * 3;
      height: $height / $width * $size;
    }

    &.icon-history {
      background-image: url('../../../user/icons/history.svg');
      $width: 18.857px;
      $height: 16px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-newsletter {
      background-image: url('../../../user/icons/newspaper.svg');
      $width: 20px;
      $height: 23px;
      width: $size;
      height: $height / $width * $size;
    }

    &.icon-lock {
      background-image: url('../../../user/icons/lock.svg');
      $width: 10px;
      $height: 15px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-up {
      background-image: url('../../../user/icons/up.svg');
      $width: 10px;
      $height: 10px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-avatar {
      background-image: url('../../../user/icons/avatar-bl.svg');
      $width: 10px;
      $height: 10px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-order {
      background-image: url('../../../user/icons/order.svg');
      $width: 10px;
      $height: 10px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-licence {
      background-image: url('../../../user/icons/licence.svg');
      $width: 10px;
      $height: 10px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-badge-info {
      background-image: url('../../../user/icons/badge-info.svg');
      $width: 20px;
      $height: 20px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-badge-alert {
      background-image: url('../../../user/icons/badge-alert.svg');
      $width: 20px;
      $height: 20px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-badge-check {
      background-image: url('../../../user/icons/badge-check.svg');
      $width: 20px;
      $height: 20px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-badge-question {
      background-image: url('../../../user/icons/badge-question.svg');
      $width: 20px;
      $height: 20px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-badge-time {
      background-image: url('../../../user/icons/badge-time.svg');
      $width: 20px;
      $height: 20px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-badge-stop {
      background-image: url('../../../user/icons/badge-stop.svg');
      $width: 20px;
      $height: 20px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }

    &.icon-badge-premium {
      background-image: url('../../../user/icons/badge-premium_color.svg');
      $width: 20px;
      $height: 20px;
      width: $size;
      height: $height / $width * $size;
      position: relative;
      top: ($height / $width * $size) / 8;
    }
  }
}

.icon-star {
  width: 26px;
  height: 26px;

  &:not(.disabled):hover {
    transform: scale(1.1);

    ~ .icon-star {
      transform: scale(1.1);
    }
  }

  .transition {
    transition-property: fill-opacity;
  }
}

.icon-my-star {
  background-image: url('../../../user/icons/star.svg');
  width: 26px;
  height: 26px;
}

.invert {
  filter: invert(1);
}
