@include media-breakpoint-down(md) {
    .px-md-0-down {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.radius-20 {
    border-radius: 20px;
}

.advert-1000 {
    max-width: 1000px;
}

.adv-text {
    line-height: 1!important;
    font-size: 14px!important;
}