.testimonials {
  margin-bottom: 180px;
}

.testimonials-content {
  @include make-col(8);
}

.testimonial-headline {
  text-align: left;
  font-size: 35px;
  line-height: 50px;
  @include media-breakpoint-down(md) {
    font-size: 24px;
    line-height: 35px;
  }
}

.testimonials-desc {
  margin-bottom: 0;
  font-size: 17px;
  line-height: 27px;
  @include media-breakpoint-down(md) {
    font-size: 15px;
    line-height: 24px;
  }
}

.testimonials-content-row {
  @include media-breakpoint-up(md) {
    @include make-row();
    justify-content: space-between;
    align-items: center;
  }
}

.testimonials-content-left {
  @include media-breakpoint-up(md) {
    @include make-col(7);
  }
}

.testimonials-content-right {
  display: none;
  text-align: right;
  @include media-breakpoint-up(md) {
    display: flex;
    @include make-col(3);
    justify-content: end;
    gap: .5rem;
  }
}

.testimonials-content-controller {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  border-radius: 100%;
  background-color: $blue;
  width: 3.5rem;
  height: 3.5rem;
  padding: .5rem;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.testimonials-content-controller-icon {
  width: 2rem;
  height: 3.5rem
}

.testimonials-slider-container {
  max-width: 100%;
  //overflow-x: auto;
  //margin-left: 10%;
  //overflow: -moz-scrollbars-none;
  //-ms-overflow-style: none;
}

.testimonials-slider-container::-webkit-scrollbar {
  width: 0;
}

.testimonials-slider {
  margin-top: 3rem;
  gap: 3rem;
  //display: flex;

  .flickity-slider {
    //margin-left: 15px;

    @include media-breakpoint-up(md) {
      //margin-left: 30px;
    }

    @media (min-width: 1650px) {
      margin-left: calc((100vw - 1950px) / 2);
    }
  }
}

.testimonials-card {
  border-radius: 30px;
  background-color: $body-color;
  width: 335px;
  position: relative;
  padding-bottom: 1rem;
  margin: 0 1rem;
  max-width: calc(100vw - 30px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    min-width: 375px;
  }
}

.testimonials-card-image {
  position: relative;
  height: 200px;
  width: 100%;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.testimonials-card-image:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, $body-color);
}

.testimonials-card-content {
  position: relative;
  min-height: 350px;
  padding: 1.25rem;
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
}

.testimonials-card-avatar {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  border-radius: 100%;
  border: solid 4px $blue;
}

.testimonials-card-play {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.5rem;
  color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonials-card-play:hover {
  color: $blue;
}

.testimonials-card-play-icon {
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
}

.testimonials-card-headline {
  position: relative;
  margin-top: -2rem;
  z-index: 9999;
  text-align: center;
  color: $white;
  font-size: 17px;
  line-height: 27px;
}

.testimonials-card-headline span {
  text-transform: uppercase;
}

.testimonials-card-content-item {
  padding: 1.25rem;
  background: $blue;
  color: $white;
  font-size: 16px;
  line-height: 19px;
  border-radius: 15px 15px 5px 15px;
}

.testimonials-card video {
  position: absolute;
  height: 210px;
}