// Color system
//
/*
$primary:           ;
$secondary:         ;
$body-color:        ;
$light:             ;
$dark:              ;
$headings-color:    ;
*/

$primary: #FF824B;

$aquamarine: #48A89B;
$aquadark: #12A79D;
$brick: #C97857;
$brown: #bf6122;
$green: #7DAA45;
$grass: #7FAB48;
$frog: #B4C642;
$orange: $primary;
$sand: #c67822;
$white: #fff;
$ocher: #E9BB2E;
$light: #FAFCFD;
$gray: #F4F5F5;
$dark-gray: #E6E6E6;
$yellow: #FFCE00;
$cyan: #CEE9E9;
$button-yellow: #EFDC00;
$dust: #E7F1F1;
$darkorange: #ed7129;
$hybrid: #C25541;
$editionSsn: #ED742B;
$merchSsn: #F2F2F2;
$euColor: #003399;


$water: #759BD0;
$sky: #5495C1;
$lightblue: #5C9AC4;
$lighterblue: #F7F7F7;
$blue: #4773B1;
$navy: #2377C1;
$paleblue: #6D9AB8;
$hover: #92BBD7;

$coming-soon-red: #BC5353;
$red:     #dc3545 !default;

$white: #fff;
$black: #3A3A3A;
$dark: #666;

$body-bg: $white;
$body-color: #3A3A3A;

$border-color: #ccc;
$input-border-color: $border-color;
$hr-border-color: $border-color;

$box-bg: #ccc;

$theme-colors: ("primary": $primary,
        "secondary": $gray,
        "danger": $red,
        "dark": $body-color,
        "blue": $blue,
        "white": $white,
        "dark-gray": $dark-gray
);

// Options
//
$enable-rounded: true;
$border-radius: 5px;

$box-shadow-sm: 5px 5px 10px rgba(0, 0, 0, .075) !default;
$box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
$box-shadow-lg: 15px 15px 30px rgba(0, 0, 0, 0.15);

// Fonts
//
$font-family-base: 'Source Sans Pro',
sans-serif;
$font-family-source-sans: 'Source Sans Pro', sans-serif;
$font-size-base: 0.875rem;

$headings-font-family: inherit;
$headings-font-weight: 700;

$text-muted: $dark;

$lead-font-size: 1.125rem;
$lead-font-weight: 400;

$text-16: 1.000rem;
$sp-20: 1.250rem;

// Links
//
$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$link-decoration: underline;

// Crumbs
//
$breadcrumb-color: $link-color;
$breadcrumb-hover-color: $link-hover-color;
$breadcrumb-active-color: $text-muted;


// Buttons + Forms
$btn-border-radius: .25rem;

$custom-control-indicator-size: 1.25rem;
$custom-checkbox-indicator-border-radius: .25rem;
$custom-control-gutter: 2rem;


// Lists
//
$list-padding: 30px !default;


// Pagination
//
$pagination-color: $link-color;
$pagination-padding-y: .5rem;
$pagination-padding-x: .75rem;
$pagination-padding-y-lg: .5rem;
$pagination-padding-x-lg: .75rem;

$pagination-icons: false;
$pagination-sprite: pagination;
$pagination-spacing: 0.25rem;

$pagination-font-size: 1rem;
$pagination-font-size-lg: 1rem;
$pagination-font-weight: 700;

$pagination-disabled-bg: $dark;
$pagination-disabled-color: $white;

// Tables
//
$table-cell-padding: .75rem;
$table-border-color: $border-color;
//$table-dark-bg:             $gray-900;
//$table-dark-border-color:   lighten($gray-900, 7.5%);
$table-dark-color: $body-bg;

// Carousel
//
$carousel-indicator-size: 0.5rem;
$carousel-indicator-spacer: 3px;
$carousel-indicator-bg: $dark;
$carousel-indicator-active-bg: $primary;

// Spacing
//
$sizes: (25: 25%,
        33: 33.3%,
        50: 50%,
        66: 66.6%,
        75: 75%,
        100: 100%);

$spacer: 1rem;
$spacers: (0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 2),
        5: ($spacer * 3),
        6: ($spacer * 4));


// Grid
//

$container-width: 1170px;
$navHeightUnder1920: 92px;
 //$container-width: 100%;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1220px,
        xxl: 1400px
);

$mq-breakpoints: (
        xs: 0px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);
$mq-show-breakpoints: (sm, md, lg, xl, xxl);

$activity-filter-height: 87px;
$activity-filter-height-mobile: 60px;
$activity-filter-height-tablet: 115px;

$enable-negative-margins: true;