.carousel {
  position: relative;
  margin-top: 2.5rem;
  width: 1920px;
  max-width: 100%;
  text-align: center;

  @include media-breakpoint-up(md) {
    margin: 8rem 2rem 0;
  }
}

.carousel-container {
  justify-content: center;
  //@include make-row();
}

.carousel-item {
  @include gradient-bg(#f2f2f2);
  display: grid;
  justify-content: space-around !important;
  align-items: center;
  border-radius: 30px;
  color: $body-color;
  padding: 4rem 2rem;
  height: 100%;

  @include media-breakpoint-up(md) {
    padding: 4rem 6rem;
    justify-content: space-between;
  }
  @include media-breakpoint-up(md) {
    @include make-row();
    //@include make-col(10);
    padding: 0;
    margin: 0;
  }
}


.carousel--primary .carousel-item {
  color: $white;
  @include gradient-bg(#ff824a);
  background-image: url(../../../images/pixel-left.png), url(../../../images/pixel-right.png);
}

.carousel-content-left {
  text-align: left;

  @include media-breakpoint-up(md) {
    @include make-col(6);
    padding: 2rem 8rem;
  }
}

.carousel-content-right {
  text-align: center;
  @include media-breakpoint-up(md) {
    @include make-col(4);
    text-align: right;
  }

  &.full-image {
    @include media-breakpoint-up(md) {
      @include make-col(6);
      height: 100%;
    }
  }

  img.with-shadow {
    box-shadow: -50px 27px 50px 0px #00000073;
  }
}

.carousel-headline {
  text-align: left;
  font-size: 35px;
  line-height: 50px;

  @include media-breakpoint-down(xxl) {
    font-size: 24px;
    line-height: 35px;
  }
}

.carousel-desc {
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 60px;

  @include media-breakpoint-down(xxl) {
    font-size: 15px;
    line-height: 24px;
  }
}

.carousel-btn {
  font-size: 17px;
  line-height: 27px;
  border-radius: 50rem;
  height: 60px;
  color: $white;
  border-color: $white;
  text-transform: none;

  &.btn-outline-black {
    color: $black;
    border-color: $black;
  }

  &.btn-carousel-sm {
    height: 43px!important;
    font-size: 15px!important;;
    font-weight: 600!important;;
    font-family: "Source Sans Pro", sans-serif!important;;
    line-height: 10px!important;;

  }
}

.carousel--primary .carousel-btn:hover {
  color: $body-color;
}

.carousel-thumb {
    width: 100%;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: .75rem;
  border-radius: .75rem;
  height: .75rem;
  margin-right: .5rem;
  margin-left: .5rem;
  text-indent: -999px;
  background-color: rgba(0, 0, 0, .5);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
}

.carousel-indicators .active {
  width: 4rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  background-color: #3a3a3a;
}

.carousel--primary .carousel-indicators li {
  background-color: rgba(255, 255, 255, .5);
}

.carousel--primary .carousel-indicators .active {
  background-color: $white;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #3a3a3a;
  text-align: center;

  &:hover {
    color: #3a3a3a;
    opacity: .5;
  }

  @include media-breakpoint-down(xxl) {
    //display: none;
  }
}

.carousel-control-prev {
  left: 4rem;
}

.carousel--primary .carousel-control-prev,
.carousel--primary .carousel-control-next {
  color: $white;

  &:hover {
    color: $white;
  }
}

.carousel-control-next {
  right: 4rem;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.hpslider {

  .flickity-page-dots {
    bottom: 25px;

    .dot {
      background: white;

      &.is-selected {
        opacity: 1;
        width: 50px;
        border-radius: 10px;
      }
    }
  }

  .flickity-button {
    top: calc(50% + 22px);
    transform: none;

    &.previous {
      background: none;
      left: 5px;

      @include media-breakpoint-up(lg) {
        left: 30px;
      }
    }

    &.next {
      background: none;
      right: 5px;

      @include media-breakpoint-up(lg) {
        right: 30px;
      }
    }

    svg {
      fill: white;
    }
  }

}

.product-preview {

  .carousel {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .flickity-page-dots {

    bottom: 0;

    .dot {
      background: #4773B1;

      &.is-selected {
        opacity: 1;
        width: 50px;
        border-radius: 10px;
      }
    }
  }

}

.product-review {

  .carousel {
    margin-top: 40px;
    margin-bottom: 40px;

    .card {
      .card-image {
        img {
          border-radius: 20px;
        }
      }
    }
  }

  .flickity-page-dots {

    bottom: 0;

    .dot {
      background: #4773B1;

      &.is-selected {
        opacity: 1;
        width: 50px;
        border-radius: 10px;
      }
    }
  }

}