.list-links {
    &, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    & {
        margin-bottom: 1.5rem;
    }

    li {
        margin-bottom: 0.375rem;
    }

    a {
        color: #707070;

        &:hover {
            color: $black;
        }
    }
}