#filtered-activity-list {
  position: relative;
  min-height: 18.25rem;

  .intent-filter {
    display: flex;
    justify-content: flex-start;

    flex-grow: 1;

    padding: 10px 40px;

    @include mq($from: sm) {
      justify-content: flex-end;
    }
  }

  .intent-filter__label {
    margin-right: 16px;

    line-height: 38px;
    font-weight: bold;
    font-size: 16px;
  }

  .intent-options {
    z-index: 10;

    cursor: pointer;

    width: 100%;
    max-width: 230px;
  }

  .intent-options__control {
    z-index: 11;

    border: 1px #B8CECE solid;
    border-radius: 10px;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    svg {
      transition-duration: 0.4s;
      transition-property: transform;
    }

    &--menu-is-open {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .intent-options__menu {
    margin-top: -8px;

    width: 100%;

    border-radius: 0 0 10px 10px;

    &-list {
      padding-top: 14px;
      padding-bottom: 8px;
    }
  }

  .intent-options__single-value {
    font-weight: bold;
    text-transform: uppercase;
  }

  .intent-option,
  .intent-option-first {
    padding: 0.5em 1.25em;
    border-bottom: #CEE9E9 1px solid;

    .label {
      text-transform: uppercase;
      font-weight: bold;
    }

    &:hover {
      background-color: #CEE9E9;
    }
  }

  .intent-option {
    >div {
      padding-left: calc(35px + 1rem);
    }

    &::before {
      content: '';
      display: block;
      float: left;
      width: 35px;
      height: 32px;

      margin: 0.375em 1em 0 0;

      vertical-align: middle;
    }

    &.revise::before {
      background: url(../../images/intent_revise.svg) no-repeat center;
    }

    &.exercise::before {
      background: url(../../images/intent_exercise.svg) no-repeat center;
    }

    &.verify::before {
      background: url(../../images/intent_verify.svg) no-repeat center;
    }

    &.involve_parents::before {
      background: url(../../images/intent_parents.svg) no-repeat center;
    }

    &.use_book::before {
      background: url(../../images/intent_use_book.svg) no-repeat center;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .activity-list {
    display: block;

    @include mq($from: md) {
      display: flex;
    }
  }
}