.achievement-toasts {
  z-index: 10000;

  .toast {
    background: white;
    margin-bottom: 10px;
  }

  .toast-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      height: 60px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
    }

    .btn-close {
      font-size: 10px;
    }
  }
}

.avatar-with-circle {
  position: relative;
  max-height: 20rem;

  label:before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 1;
    padding-top: 100%;
    border-radius: 50%;
    background: $white;
  }

  &.avatar-gray {
    label:before {
      background: $gray;
    }
  }

  img {
    position: relative;
    z-index: 2;
  }

  &.monster-item {

    label {
      cursor: pointer;
      width: 100%;

      &:after {
        display: none;
      }
    }

    input[type="radio"] {
      display: none;
    }

    img {
      transition: 200ms ease-out transform;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    input[type=radio]:checked + label {
      &:before {
        border: 2px solid #4773B1;
      }
    }

    &:hover, &.selected-item {
      &:before {
        border: 2px solid #4773B1;
      }
    }
  }
}
