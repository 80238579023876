h1, .h1 {
    margin: .67em 0;
    font-size: 1.75rem;
    text-align: center;

    &:first-child {
        margin-top: 0;
    }
}

.h1-lg {
    @include mq($from: md) {
        font-size: 2rem;
    }

    @include mq($from: lg) {
        font-size: 2.125rem;
    }
}

h2, .h2 {
    margin: 1.25em 0 .75em 0;
    font-size: 1.5rem;

    @include mq($from: md) {
        font-size: 1.75rem;
    }

    &:first-child {
        margin-top: 0;
    }
}

h3, .h3 {
    margin: 2.5em 0 1.5em 0;
    font-size: 1.125rem;

    @include mq($from: md) {
        font-size: 1.25rem;
    }

    @include mq($from: lg) {
        font-size: 1.375rem;
    }
}

h4, .h4 {
    margin: 1.12em 0;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-semi {
    font-weight: 600;
}

.text-underline {
    text-decoration: underline !important;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-xl {
    font-size: 1.125rem;
    line-height: 1.2;

    @include mq($from: md) {
        font-size: 1.25rem
    }

    @include mq($from: lg) {
        font-size: 1.75rem
    }

    @include mq($from: xl) {
        font-size: 2.1875rem;
    }
}

.text-lg {
    font-size: 1rem;
    /*line-height: 1.2;*/

    @include mq($from: md) {
        font-size: 1.125rem
    }

    @include mq($from: lg) {
        font-size: 1.5rem;
    }
}

.text-md {
    font-size: 0.9375rem;
    /*line-height: 1.2;*/

    @include mq($from: md) {
        font-size: 1rem;
    }
}

.text-sm {
    font-size: 0.8125rem;
}

.lead {
    font-style: italic;
    font-size: 1.25rem;
}

.parents-inspiration {
    font-size: 1.75rem;
}

.text-size-125 {
    font-size: 1.25rem;
}

.text-home {
    font-size: 1.875rem;
    line-height: 2rem;
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 2.5rem;
        text-align: left;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 2.8125rem;
        line-height: 3.5rem;
        text-align: left;
    }
}

.text-11 {
    font-size: 11px;
    line-height: 21px;
}

.text-12 {
    font-size: 12px;
    line-height: 22px;
    @include media-breakpoint-down(md) {
        font-size: 11px;
        line-height: 21px;
    }
}

.text-13 {
    font-size: 13px;
    line-height: 23px;
    @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 22px;
    }
}

.text-15 {
    font-size: 15px;
    line-height: 25px;
    @include media-breakpoint-down(md) {
        font-size: 13px;
        line-height: 23px;
    }
}

.text-16 {
    font-size: 16px;
    line-height: 26px;
    @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 24px;
    }
}

.text-17 {
    font-size: 17px;
    line-height: 27px;
    @include media-breakpoint-down(md) {
        font-size: 15px;
        line-height: 24px;
    }
}

.text-18 {
    font-size: 18px;
    line-height: 28px;
    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 24px;
    }
}

.text-19 {
    font-size: 19px;
    line-height: 27px;
    @include media-breakpoint-down(md) {
        font-size: 17px;
        line-height: 27px;
    }
}

.text-20 {
    font-size: 20px;
    line-height: 30px;
    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 26px;
    }
}

.text-23 {
    font-size: 23px;
    line-height: 33px;
    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 27px;
    }
}

.text-25 {
    font-size: 25px;
    line-height: 33px;
    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 30px;
    }
}

.text-30 {
    font-size: 30px;
    line-height: 40px;
    @include media-breakpoint-down(md) {
        font-size: 22px;
        line-height: 32px;
    }
}

.text-35 {
    font-size: 35px;
    line-height: 50px;
    @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 35px;
    }
}

.small, small {
    font-size: 80%;
    font-weight: 400;
}

.ff-source-sans-pro {
    font-family: 'Source Sans Pro', sans-serif;
}

.ff-segoe-ui {
    font-family: 'Segoe UI', sans-serif;
}