.button-banner {
    padding: 80px 50px 240px 50px;
    height: 100%;
    background: #FE9A57;
    background-size: 60%;
    color: #fff;
    text-align: left;
    position: relative;
    overflow: hidden;

    h2 {
        margin: 0 0 0.875em 0;
    }

    p {
        width: 75%;
    }

    img {
        position: absolute;
        right: 15%;
        bottom: -88px;
        height: 300px;
        box-shadow: 25px 25px 50px #00000066;
    }

    .btn {
        text-transform: uppercase;
    }

    &.first {
        img {
            height: 170px;
            right: -40px;
            bottom: 0;
            box-shadow: none;
        }
    }

    &.second {
        background: #FF824B;
    }

    &.third {
        background: #ED7129;
    }

    @include mq($from: md) {

        h2 {
            font-size: 1.5rem;
        }

        p {
            font-size: 1.1rem;
            max-width: 420px;
        }
    }
}
