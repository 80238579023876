$clrText: #3a3a3a;
$blue: #4773B1;
$blueDark: #3C6296;

#user-section-left{
    @include media-breakpoint-down(xl){
        display: none !important;
    }

    #intro__balloon {
        width: 75%;
        margin-left: 10%;
    }
}

#user-section-left-background {
    opacity: 1;
    width: 100%;
    background: transparent linear-gradient(270deg, #FFB300 0%, #FFCE00 100%) 0% 0% no-repeat padding-box;
}

#user-section-left-background-pixels {
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 1;
    width: 100%;
    height: 100%;
    min-height: 700px;
    background-image: url(../../../images/pixel-left.png), url(../../../images/pixel-right.png);
    background-position: 0 0, 100% 100%;
    background-repeat: no-repeat, no-repeat;
}

.section.user {
    padding-top: 0px;
}

.section.user{
    @include media-breakpoint-up(lg) {
        max-width:100%;
        .container{
            max-width: 1680px;
        }
    }
}

.user {

    .container {
        max-width: 1920px!important;
        padding: 0;
    }

    a {
        color: $clrText;
        text-decoration: underline;
    }

    .equal {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
    }

    .shadow {
        width: 100%;

        @include media-breakpoint-down(sm) {
            box-shadow: none !important;
        }
    }

    .card {
        b {
           font-weight: 700;
        }

        &:before {
            content: unset;
        }

        color: $clrText;

        @include media-breakpoint-down(sm) {
            padding: 20px 0 0 0;
        }
    }

    .card.login-code{
        @include media-breakpoint-up(sm) {
            max-height: 696px;
            padding: 40px 30px;
        }
    }

    .link {
        color: $clrText;

        &:hover {
            color: darken($clrText, 10%)
        }
    }

    .login h1 {
        padding-top: 40px;
        font-size: 24px;
        font-weight: 700;
        text-align: left !important;
        color: $clrText;
        margin-bottom: 1.4rem;
    }

    .login p {
        font-size: 14px;
        color: $clrText;
        margin-bottom: 0;
    }

    .login.login-img{
        width:100%;

        @include media-breakpoint-down(lg) {
            margin-top: 0 !important;
        }
    }

    .login .img-full {
        margin-left: auto;
        margin-right: auto;
        // margin-bottom: 20px;

        &.panacek{
            width: 80%;

            @include media-breakpoint-down(lg) {
                margin-top: 50px;
                margin-bottom: 0;
            }
        }

        &.tuzka{
            width: 30%;

            @include media-breakpoint-down(lg) {
                margin-top: 50px;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .login .lead {
        font-size: 14px;
        font-style: normal;
        color: $clrText;
        margin-bottom: 0;
    }

    .login .lead-center {
        font-size: 14px;
        color: $clrText;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            margin: 0 auto;
            text-align: center;
        }
    }

    .login form{
        width: 100%;
    }

    .form-control:not(.is-invalid) {
        color: $clrText;
        background: #F4F5F5;
        border: 1px solid #D5D5D5;
        padding: 9px 10px 15px 10px;
    }

    .button{
        transition: unset;
    }

    .button:not(label,.d-inline-block) {
        width: 100%;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .button--blue {
        background: $blue;

        &:hover, &:focus {
            background: #3C6296;
        }
    }

    .button--transparent {
        color: $blue;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid $blue;

        &:hover, &:focus {
            color: $blue;
            padding-top: 9px;
            padding-bottom: 9px;
            border: 2px solid $blue;
        }
    }

    .button--reg{
        width: 47%;
        font-style: normal;
    }

    .button-inline {
        width: auto;
        display: inline-block;
    }

    .lost-password {
        display: block;
        text-align: center;
    }

    .button--login-service {
        color: $clrText;
        padding: 10px 2rem;
        border-radius: 40px;
        background-color: white;
        border: 1px solid #D6D6D6;

        &:hover {
            background-color: white;
        }
    }

    .button--login-service.button--facebook {
        background-image: url(../../../images/icon-registrace-facebook.svg);

        &:hover {
            background-image: url(../../../images/icon-registrace-facebook.svg);
        }
    }

    .button--login-service.button--google {
        background-image: url(../../../images/icon-registrace-google.svg);

        &:hover {
            background-image: url(../../../images/icon-registrace-google.svg);
        }
    }

    .button--login-service.button--microsoft {
        background-image: url(../../../images/microsoft.svg);

        &:hover {
            background-image: url(../../../images/microsoft.svg);
        }
    }

    .divider {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #D6D6D6;
        line-height: 0.1em;
        margin: 10px 0 20px;

        span {
            padding: 0 10px;
            background: #fff;
            text-transform: uppercase;
        }
    }

    .login .code__login {
        width: 1.8rem;
        font-size: 24px;
        display: inline-block;
        padding: 2px 1px 2px 0px;
        margin-right: 2px;
        margin-left: 0px;
    }

    .login .custom-switch {
        label {
            font: 14px;
            font-weight: normal;
        }
    }

    .login input.code__login::-webkit-inner-spin-button,
    .login input.code__login::-webkit-outer-spin-button {
        margin: 0;
        opacity: 1;
        -webkit-appearance: none;
    }

    .login input[type=number]::-webkit-inner-spin-button,
    .login input[type=number]::-webkit-outer-spin-button {
        -moz-appearance: textfield;
    }

    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: $blue;
        background-color: $blue;
    }

    .custom-switch .custom-control-label::after {
        top: calc(0.03125rem + 1px);
        background-color: $blue;
    }

    .main-content{
        @include media-breakpoint-up(md){
            min-height: 696px;
        }
    }

    ul.list-item-icon-check{
        padding-top: 1em;
        padding-inline-start: 0px;
    }

    .list-item-icon-check li {
        list-style: none;
        line-height: 3.2em;
        padding-left: 3em;
        background-repeat: no-repeat;
        background-position: center left;
        background-image: url(../../../images/icon-registrace-list.big.png);

        @include media-breakpoint-down(md){
            padding-left: 2em;
            line-height: 2.2em;
            background-image: url(../../../images/icon-registrace-list.small.png);
        }
    }
}

.admin-login {
    max-width: 400px;
}