.faq-list {
  li {
    color: #4773B1;
    list-style: none;

    &:before {
      content: " ";
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
      position: relative;
      top: 4px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('data:image/svg+xml,<svg id="FAQ_Li" data-name="FAQ Li" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><rect id="Rectangle_1025" data-name="Rectangle 1025" width="20" height="20" fill="%23fff" opacity="0"/><g id="Ellipse_29" data-name="Ellipse 29" transform="translate(5 5)" fill="none" stroke="%234773b1" stroke-width="2"><circle cx="5" cy="5" r="5" stroke="none"/><circle cx="5" cy="5" r="4" fill="none"/></g></svg>');
    }

    &:hover {
      &:before {
        background-image: url('data:image/svg+xml,<svg id="FAQ_Li" data-name="FAQ Li" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><rect id="Rectangle_1025" data-name="Rectangle 1025" width="20" height="20" fill="%23fff" opacity="0"/><g id="Ellipse_29" data-name="Ellipse 29" transform="translate(5 5)" fill="none" stroke="%234773b1" stroke-width="5"><circle cx="5" cy="5" r="5" stroke="none"/><circle cx="5" cy="5" r="2.5" fill="none"/></g></svg>');
      }

      a {
        text-decoration: underline;
      }
    }

    a {
      color: #4773B1;
      text-decoration: none;
    }
  }
}