.filter__hint__circle {
  .hint--publication {
    height: 1rem;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  .hint--code {
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.form-search {
  position: relative;

  .form-code {
    position: absolute;
    top: 9px;
    left: 88px;

    width: 77px;
    height: 40px;

    background: #FFB300 0% 0% no-repeat padding-box;
    border-radius: 0.25rem;
    padding: 0.75rem;
    font-size: 1rem;
    z-index: 1;
  }

  .form-control--search {
    padding: 0.5rem 0 0.5rem 62px;
    font-size: 1rem;
    z-index: 2;
  }

  .btn-search {
    z-index: 3;
  }
}

.search-mobile {
  .form-search {
    .search-bg {
      width: 100%;
      background-color: white;
      margin-bottom: 2rem;
      border-radius: 26px 0 0 26px;
      z-index: 1;
    }

    .form-code {
      padding: 0.25rem 0.75rem;
      top: 15px;
      left: 50px;
      height: 30px;
      z-index: 0;
    }

    .form-control--search {
      margin-bottom: 0;
    }
  }
}

.filter__search {
  .form-code {
    left: 78px;
    top: 13px;
  }
}

.fancybox-container {
  .form-inline {
    width: calc(100vw - 30px);
  }

  .filter__hint__circle {
    margin-top: 10rem;
    background: #ebcf00;
  }
}