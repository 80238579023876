.teachers {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 60px 30px;
  justify-content: center;
  @include media-breakpoint-up(md) {
    @include make-row();
    padding: 0 15px 7rem 15px;
  }
}

.teachers .card__image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @include media-breakpoint-down(md) {
    min-height: 335px;
    background-position: top;
  }
}

.teachers .card__content {
  //content: " ";
  //position: absolute;
  padding-top: 3rem;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: transparent linear-gradient(180deg, #FB6161CC 0%, #FB6161 100%) 0% 0% no-repeat padding-box;

  p {
    padding: 0 2rem 1rem;
  }

}

.teachers .card-body {
  padding: 2rem 11rem;
  @include media-breakpoint-down(md) {
    padding-left: unset;
    padding-right: unset;
  }

  .btn {
    font-weight: 600;
  }
}

.teachers .card__cta {
  position: absolute;
  z-index: 99;
  top: calc(50% - 60px);
  left: -70px;
  @include media-breakpoint-down(xxl) {
    top: -30px;
    left: calc(50% - 70px);
    transform: rotate(180deg);
  }
}
