// // MEDIA QUERIES DEBUG
body:before {
    display: none;
}

body.mqdebug:before {
    display: block;
    right: auto !important;
    top: auto !important;
    left: 0;
    bottom: 0;
}

body {
    padding: 0!important;
}

@import "./_core/font";

// // zakladni promenne a mixiny
@import "./_core/variables";
@import "./_core/mixins";
@import "./_core/sprite/sprite";
@import "./_core/sprite/sprite-mixin";
@import "./_core/animations";
@import "../../../node_modules/sass-mq/mq";

// // BOOTSTRAP
@import "./_core/bootstrap";
@import "./_core/typo";
// @import "../../../node_modules/bootstrap/scss/bootstrap";


@import "./lib/flickity";
@import "./lib/jquery.fancybox";

// //  UI komponenty
@import "./components/activity/activity";

@import "./components/banner/banner";
@import "./components/base/base";
@import "./components/book/book";
@import "./components/button/button";

@import "./components/card/card";
//@import "../../general/scss/components/card";
@import "./components/carousel/carousel";
@import "./components/chat/chat";
@import "./components/class/class";
@import "./components/connection/connection";
@import "./components/canvas/canvas";
@import "../../general/scss/components/sidebar";
@import "../../general/scss/components/icons";

@import "./components/fancybox/fancybox";
@import "./components/filter/filter";
@import "./components/foot/foot";

@import "./components/forms/forms";
@import "./components/forms/forms-custom";
@import "./components/forms/parsley";

@import "./components/head/head";

@import "./components/intro/intro";
@import "./components/hybrid/hybrid";

@import "./components/list/list-links";
@import "./components/list/list-n";
@import "./components/list/list-number";
@import "./components/list/list-params";
@import "./components/list/list-icon";
@import "./components/list/list-faq";

@import "./components/nav/nav";
@import "./components/nav/nav-sub";
@import "./components/news/news";
@import "./components/newsletter/newsletter";
@import "./components/notice/notice";

@import "./components/result/result";

@import "./components/section/section";
@import "./components/section/button_banner";
@import "./components/search/search";
@import "./components/search/search-expand";
@import "./components/search-modal/search-modal";
@import "./components/slider/slider";
@import "./components/subjects/subjects";

@import "./components/table/table";
@import "./components/task/task";
@import "./components/teachers/teachers";
@import "./components/test/test";
@import "./components/testimonials/testimonials";
@import "./components/type/type";

@import "./components/user/general";
@import "./components/user/login";
@import "./components/user/register";
@import "./components/user/dashborad";
@import "./components/user/setting";

@import "./components/user/login-navbar";
@import "./components/achievement/achievement";
@import "./components/premium/premium";
@import "./components/offcanvas/offcanvas";
@import "./components/shops/shops";
@import "./components/contentPack/contentPack";
@import "../../general/scss/components/recommendation";

@import "./layout/layout";


@import "../../general/scss/customize/colour-themes";
@import "../../general/scss/components/button";
@import "./custom/colour-themes";
@import "./custom/publication-bestBook";
@import "./custom/comparison-tests";
@import "./custom/filtered-activity-list";
@import "./custom/outdated-browser-rework";
@import "./custom/publication-coming-soon";
@import "./custom/search";
@import "./custom/search-suggest";
@import "./custom/hidebeforeloadjs";
@import "./custom/custom";
@import "./custom/adbanner";