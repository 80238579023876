.slider__imgs,
.slider__text {
    .slider__cell {
        width: 100%;
    }
}

.slider__imgs {
    position: relative;
    z-index: 2;
    text-align: center;

    .flickity-button {
        display: none;
        position: static;
        transform: translateY(0);
        margin: 0 30px;

        @include mq($from: md) {
            display: inline-block;
        }
    }

    .flickity-prev-next-button {
        width: 52px;
        height: 52px;
        background: none;

        svg {
            display: none;
        }
    }

    .flickity-button.previous {
        background-image: url(../../../images/slider-prev.svg);
    }

    .flickity-button.next {
        background-image: url(../../../images/slider-next.svg);
    }

    .flickity-button:disabled {
        opacity: 1;
    }
}

.slider__img {
    padding: 30px 60px 30px 30px;

    img {
        display: block;
        margin: auto;
        box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.15);
    }

    @include mq($from: md) {
        padding-top: 0;
        padding-bottom: 40px;
        padding-left: 30px;
    }

    @include mq($from: lg) {
        padding-left: 90px;
    }
}

.slider__text {
    padding: 0 15px;
    color: #fff;

    h3 {
        margin-top: 0;
    }

    @include mq($from: md) {
        padding-left: 40px;
    }
}

.slider__status {
    display: none;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    font-weight: 700;
    color: #fff;

    @include mq($from: md) {
        display: block;
    }
}

.slider--publications {
    .slider__img {
        padding: 0;
    }

    .slider__imgs {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
    }

    .slider__imgs .flickity-button {
        display: block;
        position: absolute;
        top: 100%;
        margin-top: 30px;
    }

    .slider__imgs .flickity-button.next {
        background-image: url(../../../images/slider-next-dark.svg);
    }

    .slider__imgs .flickity-button.previous {
        background-image: url(../../../images/slider-prev-dark.svg);
    }

    .slider__status {
        display: block;
        position: static;
        margin-top: 46px;
        color: $body-color;
    }

    .slider__text {
        position: relative;
        padding-left: 0;
        color: $body-color;

        @include mq($from: md) {
            &:after {
                content: "";
                width: 320px;
                height: 1px;
                position: absolute;
                top: 85px;
                left: -200px;
                background-color: $body-color;
            }
        }

        .h2 {
            padding-bottom: 0.75em;
            min-height: 5.5rem;
        }

        .slider__cell {
            overflow: hidden;
        }

        .slider__cell.is-selected {
            overflow: visible;
        }
    }

    .variants {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 3;
        bottom: 30px;
        left: 0;
        background: #BA4A4A;
        color: white;
        font-weight: bold;
        font-size: 1.1rem;
        line-height: 1.25;
        width: 126px;
        height: 126px;
        border-radius: 50%;
        box-shadow: 15px 15px 30px 0 rgba(0, 0, 0, 0.25);


        @include media-breakpoint-between(sm, lg) {
            display: none;
        }

        @include media-breakpoint-up(sm) {
            top: calc(50% - 60px);
            left: -36px;
        }
    }
}

//
//@media (max-width:768px) and (min-width:576px) {
//    .slider--publications .variants {
//        display: none;
//    }
//}​

.slider--practice {
    .slider__img {
        padding: 0;
    }

    .slider__imgs .flickity-button {
        display: block;
        position: absolute;
        top: 100%;
        margin-top: 30px;
        margin: 30px 0 0 0;
    }

    .slider__imgs .flickity-prev-next-button.next {
        right: auto;
        left: 128px;
    }

    .slider__status {
        display: block;
        position: static;
        max-width: 180px;
        margin-top: 46px;
        text-align: center;
    }

    .slider__text {
        margin-top: 40px;
        padding-right: 0;

        @include mq($from: sm) {
            padding-left: 220px;
            margin-top: -20px;

            &:before {
                content: "";
                width: 1px;
                background: #fff;
                height: 100px;
                position: absolute;
                left: 200px;
                top: -70px
            }
        }
    }

}

.homepage-book-slider {
    display: flex;

    color: white;

    background-color: #C67822;

    overflow: hidden;

    h2 {
        font-size: 1.5rem;
    }

    .summary {
        padding: 50px 70px 30px 40px;
    }

    .slides {
        top: 78px;
        right: 60px;

        width: 183px;
        max-width: 16vw;
        min-width: 110px;

        box-shadow: 25px 25px 50px 0 rgba(0, 0, 0, 0.4);

        .slide {
            img {
                width: 183px;
                height: auto;
                max-width: 16vw;
                min-width: 110px;
            }
        }
    }
}

.slick-list {
    overflow: visible;
}