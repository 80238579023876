.marketing-banner {
  position: relative;

  display: none;
  flex-direction: column;
  align-items: center;

  flex: 1 1 0;

  z-index: 0;

  @include mq($from: md) {
    display: flex;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  }

  .book-close {
    font-size: 1rem;
    color: white;
    cursor: pointer;
  }

  &.fixed {
    justify-content: center;

    .book-close {
      display: block;
      position: fixed;
      top: 1rem;
      right: 1rem;

      .icon {
        padding-left: 0.5rem;
        font-size: 2rem;
        transform: translateY(0.3rem);
        display: inline-block;
      }
    }

    .marketing-banner__content {
      position: fixed;

      top: 50%;

      padding: 0 3em 0 5em;

      transform: translateY(-50%);

      z-index: 2;

      .book-image {
        display: inline-block;

        margin-bottom: 1.75em;
      }

      .book-title {
        margin-bottom: 0.25em;

        line-height: 1.25em;
      }
    }
  }

  &.bottom {
    justify-content: end;

    .marketing-banner__content {
      top: -16px;
    }
  }

  &__background {
    position: fixed;

    top: 0;

    z-index: -2;

    width: 100%;
    height: 400px;

    background: url(../../../images/banner-bg-pixels.png);
  }

  &__content {
    top: 58px;

    z-index: -1;

    .book-title {
      font-size: 1.6rem;
      font-weight: 700;
      color: white;
    }

    .book-claim {
      font-size: 1rem;
      color: white;
      margin-bottom: 30px;
    }
  }
}

.marketing-banner-inline {
  display: flex;

  flex: 0 0 100%;
  flex-direction: column;

  position: relative;

  background-color: white;
  color: black;

  padding: 2em;

  overflow: hidden;

  border-radius: 20px;
  margin: 10px;

  text-decoration: none;
  cursor: pointer;

  width: calc(100% - 20px);

  //&:after {
  //  content: " ";
  //  display: block;
  //  position: absolute;
  //  right: 2em;
  //  bottom: 2em;
  //  width: 17px;
  //  height: 12px;
  //  background: url(../../../images/filter-arrow.svg) no-repeat center;
  //  background-size: contain;
  //  -webkit-transition-duration: 0.3s;
  //  transition-duration: 0.3s;
  //  -webkit-transition-property: transform;
  //  transition-property: transform;
  //  -webkit-transform: translateX(60px);
  //  transform: translateX(60px);
  //}
  //
  //&:hover {
  //  color: black;
  //  &:after {
  //    -webkit-transform: translateX(0px);
  //    transform: translateX(0px);
  //  }
  //}

  @include mq($from: md) {
    grid-row-end: span 2;
  }

  .banner-title {
    color: #3A3A3A;
  }

  .banner-background {
    position: fixed;

    top: 0;

    width: 100%;
    height: 100%;

    background: url(../../../images/banner-bg-pixels.png) right top;

    @include mq($from: sm) {
      width: 50%;
    }
  }

  .book-image {
    position: relative;
    right: 0;
    margin: 40px 0;

    .tip {
      display: none;

      @include mq($from: sm) {
        display: flex;
      }
    }
  }

  .banner-close {
    font-size: 0.8rem;
    position: absolute;
    text-align: right;
    top: 0;
    right: 1.5rem;

    .icon {
      display: inline-block;
      transform: translateY(0.25rem);
      font-size: 1.6rem;
      padding-left: 0.25rem;
    }
  }

  .book-title {
    margin-bottom: 1em;

    font-size: 1.75em;
    font-weight: 700;

    @include mq($from: sm) {
      font-size: 1.5em;
    }
  }

  .book-claim {
    margin-bottom: 1em;
  }

  .half {
    flex-basis: 50%;
    padding: 0;

    @include mq($from: lg) {
      padding: 0 4em 0 0;
    }

    z-index: 0;
  }
}

.multimedia-activity-publication-banner {
  display: flex;

  align-items: center;
  flex-direction: column;

  margin-top: 60px;
  padding-top: 4rem;

  color: white;

  border-radius: 0 0 14px 14px;

  @include mq($from: md) {
    align-items: flex-end;
    flex-direction: row;

    padding-top: 65px;
  }

  .book-image {
    margin: 0 30px 0 50px;

    .tip {
      width: 66px;
      height: 66px;

      top: -36px;

      font-size: 1rem;
    }
  }

  .book-detail {
    padding: 40px;

    @include mq($from: md) {
      padding-top: 0;
    }

    .book-title {
      font-size: 22px;

      font-weight: bold;

      margin-bottom: 20px;
    }

    .book-claim {
      margin-bottom: 30px;
    }
  }
}

.book-image {
  display: inline-block;

  position: relative;

  box-shadow: 25px 25px 50px 0 rgba(0, 0, 0, 0.4);

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 53px;
    height: 74px;
    background-image: url(../../../images/page-flip-corner.png);
  }

  img {
    max-width: 185px;
  }
}

.tip {
  position: absolute;

  display: flex;

  align-items: center;

  justify-content: center;

  border-radius: 50%;

  top: -50px;
  right: 0;

  transform: translateX(50%);

  width: 100px;
  height: 100px;
  max-width: 12vw;
  max-height: 12vw;
  min-width: 66px;
  min-height: 66px;

  padding: 0 1em;

  background-color: #ebcf03;

  font-weight: 700;
  font-size: 1.5em;
  text-transform: uppercase;

  color: black;

  text-align: center;
  line-height: 1em;
}