.premium-tab-selector {

  .nav-tabs {
    border-bottom: none;

    .nav-link {
      @extend .border;

      &:hover {
        color: #3a3a3a;
      }

      &.active {
        @extend .btn-black;
        @extend .shadow-none;

        &:focus {
          box-shadow: none !important;
        }

        &:hover {
          color: white;
        }
      }
    }
  }

}

.premium-basket {

  .radio-label {
    line-height: 25px;
    font-size: 14px;

    &.active {
      font-weight: bold;
    }

    input[type='radio'] {
      width: 20px;
      height: 20px;
      margin-right: 9px;

      &:checked {
        background-color: $blue;
        border-color: $blue;
      }

      &:focus, &:active {
        box-shadow: none;
      }
    }

    span.subinfo {
      font-weight: normal!important;
      font-size: 13px;
      padding-left: 9px;
    }

  }

  .h-20px {
    height: 20px;
  }


}


.product-preview {
  .carousel-item {
    background-color: #ffffff;
    padding: 0;
    height: auto;
  }
}

.premium-offer {
  min-height: 375px;
  align-content: space-around;
  border: 1px solid #E6E6E6;
  border-radius: 20px;
  position: relative;

  .col-12:first-child {

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        border-bottom: 1px solid #E6E6E6;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 35%;
      background: url(../../../images/flag_pripravujeme.svg) no-repeat left;
      background-size: contain;
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: 175px;
    align-content: center;

    .col-12:first-child {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        right: auto;
        width: 1px;
        height: auto;
        border-bottom: none;
        border-right: 1px solid #E6E6E6;
      }

      &:after {
        height: 75%;
      }
    }
  }
}