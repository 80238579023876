$colourschemes: (
        chemie: (
                primary: #48A89B,
                primary-alt: rgba(72, 168, 155, 0.9),
                light: lighten(#48A89B, 5%),
                lighter: lighten(#48A89B, 10%),
                lightest: lighten(#48A89B, 15%)
        ),
        prirodopis: (
                primary: #7DAA45,
                primary-alt: rgba(125, 170, 69, 0.9),
                light: lighten(#7DAA45, 5%),
                lighter: lighten(#7DAA45, 10%),
                lightest: lighten(#7DAA45, 15%)
        ),
        zemepis: (
                primary: #2377C1,
                primary-alt: rgba(35, 119, 193, 0.9),
                light: lighten(#2377C1, 5%),
                lighter: lighten(#2377C1, 10%),
                lightest: lighten(#2377C1, 15%)
        ),
        fyzika: (
                primary: #4B90BE,
                primary-alt: rgba(75, 144, 190, 0.9),
                light: lighten(#4B90BE, 5%),
                lighter: lighten(#4B90BE, 10%),
                lightest: lighten(#4B90BE, 15%)
        ),
        cestina: (
                primary: #C97857,
                primary-alt: rgba(201, 120, 87, 0.9),
                light: lighten(#C97857, 5%),
                lighter: lighten(#C97857, 10%),
                lightest: lighten(#C97857, 15%)
        ),
        matematika: (
                primary: #5782C3,
                primary-alt: rgba(87, 130, 195, 0.9),
                light: lighten(#5782C3, 5%),
                lighter: lighten(#5782C3, 10%),
                lightest: lighten(#5782C3, 15%)
        ),
        prvouka: (
                primary: #96C15E,
                primary-alt: rgba(150, 193, 94, 0.9),
                light: lighten(#96C15E, 5%),
                lighter: lighten(#96C15E, 10%),
                lightest: lighten(#96C15E, 15%)
        ),
        priroda: (
                primary: #96C15E,
                primary-alt: rgba(150, 193, 94, 0.9),
                light: lighten(#96C15E, 5%),
                lighter: lighten(#96C15E, 10%),
                lightest: lighten(#96C15E, 15%)
        ),
        spolecnost: (
                primary: #9864A2,
                primary-alt: rgba(152, 100, 162, 0.9),
                light: lighten(#9864A2, 5%),
                lighter: lighten(#9864A2, 10%),
                lightest: lighten(#9864A2, 15%)
        ),
        dejepis: (
                primary: #ea5288,
                primary-alt: rgba(234, 82, 136, 0.9),
                light: lighten(#ea5288, 5%),
                lighter: lighten(#ea5288, 10%),
                lightest: lighten(#ea5288, 15%)
        ),
        obcanka: (
                primary: #DD8A6C,
                primary-alt: rgba(221, 138, 108, 0.9),
                light: lighten(#DD8A6C, 5%),
                lighter: lighten(#DD8A6C, 10%),
                lightest: lighten(#DD8A6C, 15%)
        ),
        anglictina: (
                primary: #36A9E0,
                primary-alt: rgba(54, 169, 224, 0.9),
                light: lighten(#36A9E0, 5%),
                lighter: lighten(#36A9E0, 10%),
                lightest: lighten(#36A9E0, 15%)
        ),
        rustina: (
                primary: #57AA26,
                primary-alt: rgba(87, 170, 38, 0.9),
                light: lighten(#57AA26, 5%),
                lighter: lighten(#57AA26, 10%),
                lightest: lighten(#57AA26, 15%)
        ),
        francouzstina: (
                primary: #606AAA,
                primary-alt: rgba(96, 106, 170, 0.9),
                light: lighten(#606AAA, 5%),
                lighter: lighten(#606AAA, 10%),
                lightest: lighten(#606AAA, 15%)
        ),
        nemcina: (
                primary: #F7A600,
                primary-alt: rgba(247, 166, 0, 0.9),
                light: lighten(#F7A600, 5%),
                lighter: lighten(#F7A600, 10%),
                lightest: lighten(#F7A600, 15%)
        ),
        spanelstina: (
                primary: #df5050,
                primary-alt: rgba(233, 80, 80, 0.9),
                light: lighten(#df5050, 5%),
                lighter: lighten(#df5050, 10%),
                lightest: lighten(#df5050, 15%)
        ),
        vzzs: (
                primary: #00A2B3,
                primary-alt: rgba(0, 162, 179, 0.9),
                light: lighten(#00A2B3, 5%),
                lighter: lighten(#00A2B3, 10%),
                lightest: lighten(#00A2B3, 15%)
        ),
        ukrajinstina: (
                primary: #fce40c,
                primary-alt: rgba(252, 228, 12, 0.9),
                light: lighten(#fce40c, 5%),
                lighter: lighten(#fce40c, 10%),
                lightest: lighten(#fce40c, 15%)
        ),
        ssn-matematika: (
                primary: #ED742B,
                primary-alt: rgba(237, 116, 43, 0.9),
                light: lighten(#ED742B, 5%),
                lighter: lighten(#ED742B, 10%),
                lightest: lighten(#ED742B, 15%)
        ),
        ssn-cestina: (
                primary: #DB6348,
                primary-alt: rgba(219, 99, 72, 0.9),
                light: lighten(#DB6348, 5%),
                lighter: lighten(#DB6348, 10%),
                lightest: lighten(#DB6348, 15%)
        ),
        ssn-special: (
                primary: #62ACF7,
                primary-alt: rgba(98, 172, 247, 0.9),
                light: lighten(#62ACF7, 5%),
                lighter: lighten(#62ACF7, 10%),
                lightest: lighten(#62ACF7, 15%)
        )
);

//avatar colors
$avatar-pink: #ff78bc;
$avatar-blue: #8ee4ff;
$avatar-green: #c9e044;
$avatar-yellow: #ffff05;
$avatar-purple: #e686ef;
$avatar-orange: #ff854a;
$avatar-gray: $gray;

$avatar-colors: (
        pink: $avatar-pink,
        blue: $avatar-blue,
        green: $avatar-green,
        yellow: $avatar-yellow,
        purple: $avatar-purple,
        orange: $avatar-orange,
        gray: $avatar-gray,
);
