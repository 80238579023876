.number {
    position: relative;
    margin-bottom: 50px;
    padding: 0 0 0 70px;
    font-size: 1.25rem;

    &:before {
        content: "";
        width: 55px;
        height: 55px;
        position: absolute;
        left: 0;
        top: 0
    }
}

.number--pink .number__text {
    color: #CB007E
}

.number--blue .number__text {
    color: #00ABE1
}

.number--yellow .number__text {
    color: #F6C200
}

.number--orange .number__text {
    color: #FF9651
}

.number--dark-orange .number__text {
    color: #c67822
}

.number--pink:before {
    background-image: url(../../../images/number-pink.svg)
}

.number--blue:before {
    background-image: url(../../../images/number-blue.svg)
}

.number--yellow:before {
    background-image: url(../../../images/number-yellow.svg)
}

.number--orange:before {
    background-image: url(../../../images/number-orange.svg)
}

.number--dark-orange:before {
    background-image: url(../../../images/nubmer-dark-orange.svg)
}

.number__text {
    width: 55px;
    height: 55px;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 53px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
}