.btn {
    padding: 10px 25px;
    font-weight: 600;
    font-size: 0.9375rem;
    text-decoration: none;
/*    text-transform: uppercase;*/

    &:disabled {
        &:hover {
            cursor: default;
        }
    }
}

.btn-search {
    color: #fff;
    background-color: #FB6161;
    line-height: 2.40625rem;
    display: flex;
    gap: .5rem;

    &:hover {
        color: #fff;
        background-color: darken(#FB6161,10%);
    }
    .search-ico {
        height: 1.40625rem;
    }
}

.btn-search--dark {
    color: #fff;
    background-color: #BA4A4A;

    &:hover {
        color: #fff;
        background-color: darken(#BA4A4A,10%);
    }
}

.btn-green {
    border: 0;
    background-color: #B4C642;
    font-size: 1.125rem;
    color: $body-color;
    box-shadow: inset 0 -3px 0 #92A135, 5px 5px 15px rgba(0,0,0,0.1);

    &:hover {
        background-color: darken(#B4C642,5%);
        color: $body-color;
    }
}

.btn-brick {
    border: 0;
    background-color: #B95049;
    color: #fff;

    &:hover {
        background-color: darken(#B95049, 5%);
        color: #fff;
    }
}

.btn-orange {
    border: 0;
    background-color: #C67822;
    font-size: 1.125rem;
    color: $body-color;
    box-shadow: inset 0 -3px 0 #A8671F, 5px 5px 15px rgba(0,0,0,0.1);

    &:not(:disabled):hover {
        background-color: darken(#C67822,5%);
        color: $body-color;
    }
}

.btn-yellow {
    background-color: $button-yellow;
    color: $black;

    &:not(:disabled):hover {
        background-color: darken($button-yellow,5%);
        color: $black;
    }
}

.btn-bs-lg {
    @extend .btn-lg;
}

.btn-lg {
    min-width: 180px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.25rem;
}

.btn-sm {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.btn-compact {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.btn-mini {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.btn-font-normal {
    font-size: 1em;
}

a.btn-evaluate-small {
    display: none;
    margin: 0.5em;

    @include mq($until: sm) {
        display: block;
        margin: 0.5em 4em;
    }
}

a.btn-evaluate-large {
    display: none;
    margin: 0.5em 4em;

    @include mq($from: sm) {
        display: block;
        margin: 0.5em;
    }
}

a.btn-block-small {
    display: inline;
    margin: 0.5em;

    @include mq($until: sm) {
        display: block;
        margin: 0.5em 4em;
    }
}

.btn-outline {
    border-radius: 20px;
    border: 1px solid $body-color;
    background-color: transparent;
    color: $body-color;
}

.btn-outline-white {
    border-radius: 22px;
    border: 1px solid $white;
    background-color: transparent;
    color: $white;
}

.btn--yellow {
    border-radius: 20px;
    background: #FFCE00 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 60px #00000029;
    color: $body-color;
    width: max-content;
    min-height: 60px;

    &:hover {
        background-color: darken($yellow, 10%);
        color: $body-color;
    }
}

.btn---blue {
    background-color: #4773B1;
}

.btn--blue-submit {
    background-color: #4773B1;
    color: #fff;
    width: 100%;
    font-weight: 800;
    font-size: 14px;
    padding: 5px 25px;
    height: 35px;
    position: relative;
    left: -3px;

    @include mq($from: lg) {
        border-radius: 0 0.25rem 0.25rem 0;
        width: max-content;
    }

    &:hover {
        background-color: darken(#4773B1, 10%);
        color: $white;
    }
}

.btn--search {
    background-color: #FB6161;
}

.btn-secondary {
    transition: box-shadow 300ms;

    &:hover {
        box-shadow: 0 0 0 0.2rem $gray;
    }
}

.btn-black {
    background-color: $black;
    border-color: $black;
    color: $white;
    box-shadow: 0 0 0 0.2rem $black;
    &:hover {
        color: $white;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem $black!important;
    }
}

.btn-white {
    &:hover {
        background-color: #e9e6e6;
        border-color: #e9e6e6;
    }
}

.btn-outline {
    &:hover {
        background-color:  $gray;
    }
}

.btn-check:checked + .btn--outline-blue {
    background-color: #4773B1!important;
    color: $white!important;
}

.btn--bolder {
    font-weight: bold;
    font-size: 15px;
}

.practice-animation-box {
    min-width: 240px;
    text-align: center;

    .practice-animation {
        width: 180px;
        animation: practiceAnimationFramse 4s ease 0s 5 normal both;
    }
}

.subject-tag {
    border: none;
    border-radius: $sp-20;
    line-height: 0;
    padding: 0;
    height: 3.125rem;
    width: 3.125rem;
    vertical-align: text-top;
    text-align: center;
    font-family: $font-family-source-sans;
    font-weight: 700;
    font-size: $text-16;

    &.subject-tag-sm {
        border-radius: .75rem;
        font-size: 14px;
        width: 2.25rem;
        height: 2.25rem;
        min-width: 2.25rem;
    }
}

@keyframes practiceAnimationFramse {
    0% {
        animation-timing-function: ease-out;
        width: 180px;
    }

    10% {
        animation-timing-function: ease-in;
        width: 200px;
    }

    20% {
        animation-timing-function: ease-out;
        width: 180px;
    }

}