@mixin colour-themes-hover-effect {
  &.card {
    &:hover {
      .card-footer {
        &:before {
          width: 45%;
        }
      }
    }
  }

  &.icon {
    display: flex;
    justify-content: center;
    align-content: center;

    line-height: 50px;

    color: white;
    font-size: 1.25rem;

    text-transform: uppercase;
  }
}

.white {
  color: white;
}

@each $colourschemeName, $colourscheme in $colourschemes {
  @each $shade, $colour in $colourscheme {
    @if $shade == primary {
      .subject-tag.#{$colourschemeName} {
        background-color: #{$colour} !important;

        span {
          color: black;
        }
      }
    } @else {
      .subject-tag.#{$colourschemeName}-#{$shade} {
        background-color: #{$colour} !important;
      }
    }

    @include mq($from: xl) {
      .quarter .#{$colourschemeName}--#{$shade}-3 {
        background-color: #{$colour} !important;

        @include colour-themes-hover-effect;
      }
    }

    .#{$colourschemeName}--#{$shade}, .quarter .#{$colourschemeName}--#{$shade}-4, .third .#{$colourschemeName}--#{$shade}-4 {
      background-color: #{$colour};

      @include colour-themes-hover-effect;
    }
  }
}

@each $key, $color in $avatar-colors {
  .c-#{"" + $key} {
    background: $color;
    fill: $color;
  }
}


.bg-blue {
  background-color: #4773B1;
}

.bg--gray {
  background-color: $gray;
}

.bg--eu {
  background-color: $euColor;
}

.bg--warning {
  background-color: $ocher;
}

.bg--dark-gray {
  background-color: #D5D5D5;
}