.publication-coming-soon {
  display: flex;

  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;

  .publication-image {
    height: 245px;

    img {
      max-width: 155px;
      max-height: 250px;
    }
  }
}
