.book {
    max-width: 760px;
    margin: auto;

    @include mq($from: sm) {
        display: flex;
    }


    @include mq($from: lg, $until: xxl) {
        margin-right: -15px;
    }
}

.book--wide {
    max-width: 960px;
}

.book--alt {
    @include mq($from: md) {
        .book__desc {
            order: 2;
        }

        .book__img {
            order: 1;
        }
    }
}

.book__desc {
    flex: 1 0 0;
    order: 1;
    padding-right: 2rem;
    padding-left: 2rem;
    min-width: 280px;
}

.book__img {
    margin: 0 auto 2rem auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 390px;

    img {
        display: block;
        width: 240px;
        height: auto;
        max-width: none;
        margin: auto;
        box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.1);
    }

    @include mq($from: sm) {
        margin-bottom: 0;
        order: 2;
    }

    //@include mq($from: lg, $until: xxl) {
    //    max-width: 160px;
    //    // overflow: hidden;
    //}
}

.book--wide {
    //    overflow: hidden;

    .book__img {
        img {
            width: auto;
            max-width: 100%;
        }
    }

    .book__img--double {
        img {
            width: 240px;
            max-width: none;
        }
    }

    @include mq($from: lg, $until: xxl) {
        margin-right: auto;

        .book__img {
            max-width: none;
        }
    }
}

.book-edition-ssn {
    .book__desc {

        @include mq($from: lg) {
            max-width: 50%;
        }
    }

    .book__img {
        width: 250px;
        max-width: 50%;
    }
}

.ssn-special--primary {
    .book__img img {
        width: 100%;
    }
}

.book__img--double {
    position: relative;
    padding-right: 120px;
    padding-bottom: 20px;

    img {
        position: relative;
        z-index: 3;
    }

    img+img {
        position: absolute;
        z-index: 1;
        left: 100px;
        top: 20px;
    }
}

.book--more {
    margin: auto;
    width: 240px;
    height: 240px;
    border-radius: 120px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    background-color: $frog;
    padding: 0 40px;
    justify-content: center;
    font-size: 1.25rem;
}