.list-icon {

    list-style: none;
    padding: 0;

    li {
        position: relative;
        margin-bottom: 50px;
        padding: 0 0 0 70px;
        font-size: 1.25rem;

        &:before {
            content: "";
            width: 50px;
            height: 50px;
            position: absolute;
            left: 0;
            top: 0;
            background-size: cover;
        }
    }

    &.list--checked {
        li:before {
            background-image: url(../../../images/check_100.png);
        }
    }

    &.list--thumb {
        li:before {
            width: 40px;
            height: 40px;
            background-image: url(../../../images/icon_thumb.png);
        }
    }
}