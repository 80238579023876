.user {
  .registration .form-group p {
    color: #707070;
    font-size: 0.813rem;
    font-weight: 400;
    letter-spacing: 0.016rem;
    line-height: 1.063rem;
  }

  .registration .form-group a {
    color: #008ebe;
    text-decoration: underline;
  }

  .registration .lead {
    max-width: 21.875rem;
    ;
  }

  .heading1 {
    color: #3a3a3a;
    font-size: 1.375rem;
    font-weight: 700;
    letter-spacing: 0.028rem;
  }

  .bg-lightGrey {
    background: #F4F5F5;
  }

  .border-radius-5 {
    border-radius: 5px;
  }

  .select-colors {
    padding: 20px 0;
    color: transparent;

    input[type="radio"] {
      display: none;
    }

    label {
      cursor: pointer;
      border: 2px solid transparent;
    }

    input[type="radio"]:checked+label {
      border: 2px solid #4773B1;
    }

    label {
      border: 2px solid transparent;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .registration .role-selection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    a {
      text-align: center;
      flex: 1 1 33%;
      box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
      cursor: pointer;
      padding: .8125rem 1rem;
      border: 0.063rem solid #b8b8b8;
      background-color: #ffffff;
      color: #3a3a3a;
      font-size: 0.938rem;
      font-weight: 700;
      letter-spacing: 0.019rem;
      border-radius: 0.625rem;

      @include media-breakpoint-up(md) {
        &:nth-of-type(2) {
          border-radius: 0;
        }

        &:first-of-type {
          border-radius: 0.625rem 0 0 0.625rem;
          border-right: 0;
        }

        &:last-of-type {
          border-radius: 0 0.625rem 0.625rem 0;
          border-left: 0;
        }
      }

      &.active {
        box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
        background-color: #4773B1;
        border-color: #4773B1;
        color: white;
      }
    }
  }
}

.multyRegistration {
  .card h2 {
    font-size: 1rem;
    font-weight: 700;
  }

  .card img {
    @include media-breakpoint-up(lg) {
      margin: unset;
    }

    width: 160px;
  }

  // .card {
  //   padding: 0;
  //   margin: 30px 15px;

  //   @include media-breakpoint-up(sm) {
  //     margin: 40px 50px;
  //   }

  //   @include media-breakpoint-up(lg) {
  //     min-height: 36.563rem;

  //     &--book-effect {
  //       margin-right: 60px;
  //     }
  //   }
  // }

  .shadow {
    border-radius: 0.625rem;
  }

  .book-shadow {
    height: 100%;
    box-shadow: 0 1.875rem 3.75rem rgba(0, 0, 0, 0.16);
  }

  .year-born {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  .year-born label {
    @include media-breakpoint-up(md) {
      flex: 1 1 25%;

      &:nth-of-type(2) {
        margin: 0 7.5px;
      }

      width: 6.25rem;
    }

    color: #3a3a3a;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.02rem;
  }

  .year-born select {
    &:focus {
      outline: none;
    }

    cursor: pointer;
    width: 100%;
    padding: 8px 16px;
    box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
    border-radius: 0.625rem;
    border: 0.063rem solid #cee9e9;
    background-color: #ffffff;
    color: #3a3a3a;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.02rem;
  }

  .custom-radio-wrap {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;

    // @include media-breakpoint-up(md) {
    flex-direction: row;
    // }

    input[type="radio"] {
      visibility: hidden;
      width: 0;
      border: none;
    }
  }

  .custom-radio-wrap label {
    text-align: center;
    flex: 1 1 33%;
    box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
    cursor: pointer;
    padding: .8125rem 1rem;
    border: 0.063rem solid #b8b8b8;
    background-color: #ffffff;
    color: #3a3a3a;
    font-size: 0.938rem;
    font-weight: 700;
    letter-spacing: 0.019rem;
    // border-radius: 0.625rem;

    // @include media-breakpoint-up(md) {
    &:nth-of-type(2) {
      border-radius: 0;
    }

    &:first-of-type {
      border-radius: 0.625rem 0 0 0.625rem;
      border-right: 0;
    }

    &:last-of-type {
      border-radius: 0 0.625rem 0.625rem 0;
      border-left: 0;
    }

    // }
  }

  .custom-radio-wrap input[type="radio"]:checked+label {
    box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
    background-color: #4773B1;
    border-color: #4773B1;
    color: white;
  }
}

.user .teacher-registration {
  .collapsed .button svg {
    transition: unset;
    transform: unset;
  }

  .show svg {
    transition: unset;
    transform: unset;
  }

  .button {
    margin: 0 .25rem 0.25rem .25rem;
  }

  &__where {
    display: flex;
  }

  &__where input {
    max-width: 16.25rem;
    width: 100%;
    border-radius: 0.313rem 0 0 0.313rem;
  }

  &__where .button--blue {
    border-radius: 0 0.313rem 0.313rem 0;
    margin: 0;
  }

  input[type="checkbox"]:checked+label {
    background: white;
    box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
  }
}

#checkSubjects .button {
  cursor: default;

  label {
    cursor: pointer;
  }
}

#checkSubjects svg.ml-3 {
  margin-left: 3px;
}

#checkSubjects svg.mt-n3 {
  margin-top: -2px;
}

#activation_newsletters {
  padding-left: 2rem;
}

#activation_avatar,
#student_avatar_edit_avatar {
  label.required {
    &:after {
      display: none;
    }
  }
}

#schoolWrapper {
  .school-item {
    margin-bottom: 1rem;

    .close {
      cursor: pointer;
    }
  }

  &.border-bottom {
    margin-bottom: 1rem;
  }
}