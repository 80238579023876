.head {
    position: relative;
    z-index: 1000;
    background-color: #fff;

    header {
        width: 100%;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 1rem;

        @include mq($from: md) {
            padding: 0;
        }
    }

    @include mq($from: md) {
        header {
            position: static;
        }
    }

    @include mq($until: md) {
        margin-top: 15px;
        width: 100%;
    }
}

.head-notification {
    position: relative;
    z-index: 11;
    padding-top: 15px;
    border-bottom: 1px solid #cee9e9;
    background-color: white;
    width: 100%;
    max-width: 1920px;
    margin: auto;

    .container {
        padding-bottom: 15px;
    }

    .col {
        padding-left: 0;
        padding-right: 0;
    }
}

.head-notification--cnt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.head-notification--info {
    flex: 0 0 60px;
    margin-right: 15px;
    padding-top: 15px;
    padding-left: 2px;
    font-weight: 600;
    font-size: 1.4em;
    width: 60px;
    height: 60px;
    color: white;
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    background: #759BD0 0% 0% no-repeat padding-box;
}

.btn-notification-close {
    padding: 0.3rem 0.7rem 0.3rem 32px;
    position: relative;
    min-width: 135px;
    top: calc(50% - 15px);

    &:before {
        content: ' ';
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        top: 10px;
        left: 7px;
        background: url(../../../images/icon-close.png) no-repeat;
    }

    &:hover {
        &:before {
            filter: invert(1);
        }
    }
}