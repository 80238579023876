.clickable:not(:disabled) {
  cursor: pointer;
}

.max-width-28px {
    max-width: 28px;
}

.w-lg-50 {
  @include media-breakpoint-up(lg) {
    width: 50% !important;
  }
}

.w-xl-75 {
  @include media-breakpoint-up(xl) {
    width: 75% !important;
  }
}

.w-md-75 {
  @include media-breakpoint-up(md) {
    width: 75% !important;
  }
}

.bottom-5 {
  bottom: 5%;
}

.zindex-1 {
  z-index: 1;
}

.pointer-event-none {
    pointer-events: none;
}

.vertical-super {
  vertical-align: super;
}

.vertical-middle {
  vertical-align: middle;
}

.clickable {
    cursor: pointer;
}