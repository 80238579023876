.foot {
  //max-width: 1920px;
  padding: 2rem 0 0;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  z-index: 200;

  footer {
    //max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 15px 60px;
    font-size: 0.8125rem;
    text-align: center;
    background-color: white;
  }

  @include mq($from: sm) {
    footer .page-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
    }
  }

  .navbar {
    padding: 0 10px;
    background: #FAFAFA 0% 0% no-repeat padding-box;
  }

  @include mq($until: md) {
    .navbar-collapse {
      display: none !important;
    }

    .navbar {
      display: flex;
      justify-content: space-between;
    }
  }
}

.foot__copy {
  display: inline-block;
  margin-bottom: 2rem;
  text-align: left;
  padding-left: 80px;
  background: url(../../../images/fraus.png) no-repeat left;

  @include mq($from: sm) {
    margin-bottom: 0;
  }
}

.foot__rp {
  display: inline-block;
  position: relative;
  padding-left: 32px;

  &:before {
    content: "";
    width: 25px;
    height: 25px;
    background: url(../../../images/peppers.svg) no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -11px;
  }

  a {
    text-decoration: none;
    color: #3A3A3A;

    &:hover {
      color: #E30613;
    }
  }
}

.comgate-logo {
  margin-top: 20px;
  max-width: 280px;
}

.foot__top {
  width: 60px;
  height: 60px;
  cursor: pointer;

  @include mq($from: md) {
    display: none;
  }
}

.foot__center {
  @include mq($from: sm) {
    max-width: 33%;
  }

  text-align: center;
}