.chat {
    font-family: "Segoe UI", Arial, sans-serif;
}

.chat__item {
    margin-bottom: 15px;
    padding: 0 20px;
}

.chat__in, .chat__out {
    position: relative;
    display: inline-block;
    padding: 16px 20px;
    border-radius: 10px;
    box-shadow: 0 6px 30px rgba(0,0,0,0.16);

    &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        bottom: 0;
    }
}

.chat__in {
    background: #759BD0;
    color: #fff;
    font-size: 0.9375rem;
    font-weight: 700;
    border-bottom-left-radius: 0;

    &:after {
        left: -17px;
        border-width: 0 0 15px 18px;
        border-color: transparent transparent #759BD0 transparent;
    }
}

.chat__out {
    padding: 18px 20px;
    margin-left: 30px;
    border-radius: 10px;
    background: #fff;
    font-size: 0.9375rem;
    border-bottom-right-radius: 0;

    @include mq($from: sm) {
        margin-left: 60px;
    }

    &:after {
        right: -17px;
        border-width: 15px 0 0 18px;
        border-color: transparent transparent transparent #fff;
    }

    &.frog {
        background: $frog;
        &:after {
            border-color: transparent transparent transparent $frog;
        }
    }

}

.chat__in, .chat__out {
    transform: scale(0);
    transition: transform .4s;

    .chat__item:nth-child(2) & {transition-delay: .5s}
    .chat__item:nth-child(3) & {transition-delay: 1s}
    .chat__item:nth-child(4) & {transition-delay: 1.5s}
    .chat__item:nth-child(5) & {transition-delay: 2s}
    .chat__item:nth-child(6) & {transition-delay: 2.5s}
    .chat__item:nth-child(7) & {transition-delay: 3s}
    .chat__item:nth-child(8) & {transition-delay: 3.5s}
    .chat__item:nth-child(9) & {transition-delay: 4s}
    .chat__item:nth-child(10) & {transition-delay: 4.5s}
}

.section--active .chat__out,
.section--active .chat__in
{
    transform: scale(1);
}
