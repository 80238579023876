//side-menu
$side-menu-animation-length: .75s;

.sidebar {
  position: fixed;
  width: 15rem;
  height: 100vh;
  padding: 1.25rem 0;
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;

  header {
    width: 100%;
    padding: 0 1rem;
  }

  &:not(.offcanvas) {
    transition: width ease-in-out $side-menu-animation-length;
  }

  .sidebar-search {
    margin: 1.5rem .625rem 1rem;

    .input-group {
      flex-wrap: nowrap;
    }
  }

  .nav {
    margin-left: 1.5rem;
  }

  .logo-toggler {
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .navbar-collapse {
    width: 100%;
  }

  .nav-item {
    font-family: $font-family-source-sans;
    align-items: center;
    justify-items: center;
    font-size: $text-16;
    height: 3.5rem;
  }

  .nav-item .active {
    color: $primary;
  }

  .nav-link {
    align-items: center;
  }
}

.sidebar-toggled {
  width: 3.75rem;
}

.sidebar-toggled .nav-link {
  margin: 0 0;
}

.sidebar .nav-link span,
.sidebar .nav-link div,
.sidebar #toggler,
.sidebar .btn-search,
.sidebar .btn-recommendations span,
.sidebar .role-selection,
.sidebar .input-group input {
  opacity: 100%;
  transition-duration: $side-menu-animation-length;
  transition-timing-function: ease-in-out;
  transition-property: opacity, margin;
}

.sidebar .only-toogled {
  opacity: 0;
  transition: opacity ease-in-out $side-menu-animation-length;
}

.sidebar .nav {
  overflow: hidden;
}

.sidebar .nav-link {
  white-space: nowrap;
}

.sidebar-toggled nav {
  overflow-x: hidden;
}

.sidebar-toggled .nav-link span,
.sidebar-toggled .nav-link div,
.sidebar-toggled #toggler,
.sidebar-toggled .btn-search,
.sidebar-toggled .btn-recommendations span,
.sidebar-toggled .nav-logout,
.sidebar-toggled .role-selection,
.sidebar-toggled .input-group input {
  opacity: 0;
}

.sidebar-toggled .btn-recommendations {
  overflow: hidden;
  padding-left: 0.5rem;
  width: 80%;
  margin: auto;
  border-radius: 0.9rem!important;

  div {
    opacity: 1;
  }
}

//.sidebar-toggled .btn-recommendations {
//  margin: 2rem 5% 0;
//}

.sidebar-toggled .only-toogled {
  opacity: 100%;

  .icon-search {
    filter: brightness(0.1);
  }

  .viky-animation {
    opacity: 1;
    margin-right: 0;

    div {
      opacity: 1;
    }
  }
}

.sidebar-toggled .btn-search {
  margin: 2rem 5% 0;
}

.sidebar-toggled .input-group #search-sidebar {
  border-radius: 50rem;
}

.sidebar-toggled .sidebar-smaller .icon {
  transform: rotateY(180deg);
}

.sidebar-smaller {
  position: absolute;
  background-color: $white;
  width: 1rem;
  height: 3.75rem;
  right: -1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid $body-bg;
  top: calc(50% - 1.85rem);

  .icon {
    transition: all $side-menu-animation-length ease-in-out;
  }
}

#main-content {
  margin-left: auto;
  padding-left: $sp-20;
  padding-right: $sp-20;
  width: 0;
  flex: 1 1 auto;
  transition: margin-left ease-in-out $side-menu-animation-length;
}

@include media-breakpoint-up(md) {
  #main-content {
    margin-left: 16rem;
  }

  .sidebar-toggled ~ #main-content {
    margin-left: 4.75rem
  }
}

@include media-breakpoint-down(md) {
  #sidebar {
    display: none;
  }
}
