.form {
  margin: $spacer 0;
}

.form-group {
  margin-bottom: $spacer;
}

textarea {
  height: 120px;
}

select.select-blank {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

select.select-blank::-ms-expand {
  display: none;
}

.invalid-feedback {
  font-size: 100%;
}

label.required:not(.form-check-label) {
  &:after {
    content: '*';
    padding-left: 0.25rem;
    color: $danger;
  }
}

.search-inline {
  display: flex;
  align-items: stretch;
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  margin: 0 auto;
  transition-property: width, left, top;
  transition-timing-function: ease;
  transition-duration: 500ms;

  .form-control {
    border-radius: 26px 0 0 26px;
    border-right: none;
    height: 50px;
    padding-left: 18px;
    padding-right: 18px;
  }

  .btn {
    border-radius: 0 25px 25px 0;
    border-left: none;
    height: 50px;
  }
}

.search-inline--lg {
  display: block;
  align-items: stretch;

  .form-control {
    display: none;
  }

  .search-ico {
    border-right: none;
    border-radius: 2rem 0 0 2rem;
    width: 6rem;
    height: 3.75rem;
    color: $body-color;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;

    &.with-border {
      border: 1px solid #ccc;
      border-right: none;
    }

    svg {
      height: 1.40625rem;
    }
  }

  //@include media-breakpoint-up(md) {
    .form-control {
      display: block;
      border-radius: 26px 0 0 26px;
      height: 3.75rem;
      padding-left: 18px;
      padding-right: 18px;
      border: none;
    }
  //}

  .no-radius {
    border-radius: 0;
    border-left: none;
  }

  .btn {
    border-radius: 26px;
    height: 3.75rem;
    margin: 0 auto;
  }

  //@include media-breakpoint-up(md) {
    .btn {
      border-radius: 0 2rem 2rem 0;
      margin: unset;
    }
  //}

  input.with-border {
    border: 1px solid #ccc;
    border-left: 0;
    padding-left: 1rem;
  }
}

@include mq($from: md) {
  form.search-inline.with-start:after {
    content: " ";
    position: absolute;
    left: 43%;
    bottom: -120px;
    width: 170px;
    height: 62px;
    background: url(../../../images/start-here.svg) no-repeat;
    pointer-events: none;
    background-size: contain;
  }
}

.section--frog .form-control {
  display: block !important;
}

.section--frog .search-inline {
  margin: unset !important;
  width: 100%;
}

.form-control {
  color: #3A3A3A;
  font-weight: 600;

  ::-webkit-input-placeholder {
    color: #5e5e5e;
  }

  ::-moz-placeholder {
    color: #5e5e5e;
  }

  :-ms-input-placeholder {
    color: #5e5e5e;
  }

  :-moz-placeholder {
    color: #5e5e5e;
  }
}

.section--frog .search-inline--lg:after {
  display: none;
}

.form-error-icon {
  display: none;
}

.profile-filter.filter__search form {
  background-color: transparent;
  box-shadow: none;
  margin-top: 18px;
}

.filter--detail .filter__search form {
  background-color: transparent;
  box-shadow: none;
}