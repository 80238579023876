.section--test {
    position: relative;
    display: flex;
    width: 100%;
    min-height: calc(100vh - 87px + 110px);
    padding: 0;
    background-color: #00A7D1;
    background-image: url(../../../images/test-home-kalibro.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-attachment: fixed;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: -110px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
    }

    @include mq($until: sm) {
        background-image: url(../../../images/test-home-mobile-kalibro.jpg);
    }

    @supports (-webkit-overflow-scrolling: touch) {
        background-attachment: local !important;
    }
}

.test {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    @include container();
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;

    @include mq($from: md) {
        min-height: calc(100vh - 87px);
        padding-bottom: 110px;
    }

    h1 {
        color: #FBE501;
        font-size: 1.75rem;

        @include mq($from: md) {
            font-size: 2rem;
        }

        @include mq($from: lg) {
            font-size: 2.25rem;
        }
    }
}

.test__top {
    padding: 40px 0 60px 0;

    .h2 {
        font-size: 1.25rem;

        @include mq($from: sm) {
            font-size: 1.5rem;
        }

        @include mq($from: md) {
            font-size: 1.75rem;
        }
    }

    .btn {
        position: relative;

        &:after {
            content: "";
            width: 39px;
            height: 44px;
            background: url(../../../images/test-arrow.svg) no-repeat;
            background-size: contain;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-top: 15px;
            animation: bounce 2s infinite;
        }
    }
}

@keyframes bounce {

    0%,
    60%,
    100% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(-10px);
    }
}

.test__center {
    flex: 1 0 auto;
    justify-content: center;
    display: flex;
    align-items: center;

    @include mq($from: sm) {
        padding: 30px 0;
    }
}

.test__bottom {
    padding: 10px 0 30px 0;
    font-size: 1.0625rem;

    @include mq($from: sm) {
        padding-top: 60px;
    }
}

.test__text {
    max-width: 420px;
}