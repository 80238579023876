.connection {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 60px 30px;
  justify-content: center;
  @include media-breakpoint-up(md) {
    @include make-row();
    padding: 0 15px 11rem 15px;
  }
}