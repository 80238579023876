.intro__form {
  height: 59px;

  &--content {
    width: 100%;
    transition: 0.5s ease;
    transition-property: width, transform;
    z-index: 801;
  }

  .form-search {
    .form-control--search {
      padding-left: 59px;
    }
  }

  &--backdrop {
    display: none;
    position: fixed;
    z-index: 800;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 1s ease;
  }

  // !! Duplicate below if you make changes !!
  &:focus-within {
    & > .intro__form--content {
      //position: absolute;
      //width: 80vw;
      //transform: translateX(calc(-0.4 * 1170px + 10vw - (100vw - 1170px) / 2));

      & > form {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .hint {
        display: block;
      }
    }

    .intro__form--backdrop {
      @include mq($from: sm) {
        display: block;
      }
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  // Needs to be duplicated due to Edge bug related to unsupported selector (:focus-within)
  &.focus {
    & > .intro__form--content {
      position: absolute;
      width: 80vw;
      transform: translateX(calc(-0.4 * 1170px + 10vw - (100vw - 1170px) / 2));

      & > form {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .hint {
        display: block;
      }
    }

    .intro__form--backdrop {
      @include mq($from: sm) {
        display: block;
      }
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.search--hint {
  padding-left: 0;
  padding-top: 2rem;

  &:before {
    position: static;
    display: inline-block;
    background-position: center;
  }
}


.mh-auto {
  min-height: auto!important;
}

.faq-search {
  @include mq($until: md) {
    .search-ico {
      display: none;
    }

    input {
      border-left: 1px solid #ccc!important;;
      border-radius: 2rem 0 0 2rem!important;
    }
  }
}