.btn--blue {
  border-radius: 20px;
  background-color: #4773B1;
  color: $white;
  width: max-content;
  font-weight: 400;
  font-size: 17px;

  &:hover {
    background-color: darken(#4773B1, 10%);
    color: $white;
  }
}

.btn--outline-blue {
  border-radius: 20px;
  color: #4773B1!important;
  border-color: #4773B1;
  width: max-content;
  font-weight: 400;
  font-size: 17px;

  &:hover {
    background-color: darken(#4773B1, 10%);
    color: $white!important;
  }
}