.card {
  position: relative;
  border-radius: 20px;
  border: 0;
  color: #fff;
  text-decoration: none;

  z-index: 1;

  .font-weight-normal {
    font-weight: 300;
  }

  &--outer {
    &:hover {
      background: $hover;
    }
  }

  &--bordered:not(.card--blank):hover {
    text-decoration: none;
    color: #fff;

    &:before {
      display: block;
      position: absolute;

      opacity: 0.2;

      content: '';

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      border: 4px white solid;
    }
  }

  a {
    text-decoration: none;
    color: white;
  }

  &-bg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &--blank {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &.card--description-function {

    hr {
      opacity: 0.1;
      margin-left: -2rem;
      margin-right: -2rem;
    }

    .border-top {
      border-color: #cccccc70 !important;
    }

  }

  &.card--order {
    .card-header {
      padding: 1rem 2rem;
      border-bottom-width: 4px !important;
    }

    .border--end {

      position: relative;

      &:after {
        display: block;
        content: " ";
        border-bottom: 4px solid $dark-gray;
        position: absolute;
        left: -1.2rem;
        right: -1.2rem;
        bottom: 0;
        height: 1px;
      }

      @include media-breakpoint-up(lg) {
        &:after {
          border-right: 1px solid $dark-gray;
          border-bottom: none;
          position: absolute;
          bottom: -2rem;
          top: -2rem;
          right: 0;
          width: 1px;
          left: unset;
          height: auto;
        }
      }
    }

    .border--top {

      position: relative;

      &:after {
        display: block;
        content: " ";
        border-top: 4px solid $dark-gray;
        position: absolute;
        left: -1.7rem;
        right: -1.7rem;
        top: 0;
        height: 1px;

        @include media-breakpoint-up(lg) {
          left: -2rem;
          right: -2rem;
          border-top: 1px solid $dark-gray;
        }
      }
    }

    .border-bottom {
      border-color: $dark-gray !important;
    }

    del {
      text-decoration-thickness: from-font;
    }

  }
}

a.card {
  cursor: pointer;

  &.card--blank {
    cursor: default;
  }
}

.card--brown {
  background-color: $brown;
}

.card--sand {
  background-color: $sand;
}

.card--brick {
  background-color: $brick;
}

.card--hybrid {
  background-color: $hybrid;
}

.card--editionSsn{
  background-color: $editionSsn;
}

.card--merchSsn{
  background-color: $merchSsn;
  color: $black;

  &.card--link {
    &:after {
      background: url('data:image/svg+xml,<svg id="Arrow_Go_Icon" data-name="Arrow Go Icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"> <rect id="space" width="15" height="15" fill="%23FFFFFF" opacity="0"/> <path id="Path_5033" data-name="Path 5033" d="M3685.775,565.366a.75.75,0,0,1-.585-1.219l3.225-4.031-3.225-4.031a.75.75,0,1,1,1.171-.937l3.6,4.5a.75.75,0,0,1,0,.937l-3.6,4.5A.748.748,0,0,1,3685.775,565.366Z" transform="translate(-3679.776 -552.616)" fill="%23FFFFFF"/></svg>') no-repeat center #3A3A3A!important;
    }

  }
}

.card--red {
  background: #FB6161 0% 0% no-repeat padding-box;
}

.card--green {
  background-color: $green;
}

.card--gray {
  background-color: #D5D5D5;
}

.card--aquamarine {
  background-color: $aquamarine;
}

.card--aquadark {
  background-color: $aquadark;
}

.card--ocher {
  background-color: $ocher;
}

.card--orange {
  background-color: $orange;
}

.card--water {
  background-color: $water;
}

.card--sky {
  background-color: $sky;
}

.card--blue {
  background-color: $blue;
}

.card--lightblue {
  background-color: $lightblue;
}

.card--navy {
  background-color: $navy;
}

.card--yellow {
  background-color: $yellow;
  @include gradient-bg($yellow);
}

.card--paleblue {
  background-color: $paleblue;
}


.card--link {
  padding-bottom: 15px;

  &:after {
    content: "";
    width: 17px;
    height: 12px;
    position: absolute;
    right: 12px;
    bottom: 12px;
    background: url(../../../images/arrow.svg) no-repeat center;
    background-size: contain;
    transition: right .3s;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color .3s;
    border-radius: 20px;
  }

  &:hover {
    &:before {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:after {
      right: 10px;
    }
  }
}

.card--link-out {
  &:after {
    bottom: 3px;
    width: 17px;
    height: 30px;
    background: url(../../../images/box-arrow-right-01.svg) no-repeat center;
  }
}

.card-header {
  display: flex;
  border: 0;
  padding: 2rem 2rem 0;
  font-size: 1rem;
  background: none;
  color: #fff;
}

.card-meta {
  display: flex;
  align-items: center;
}

.card-difficulty {
  width: 30px;
  height: 14px;

  background: no-repeat center;
  background-size: contain;

  &.A {
    background-image: url(../../../images/level1.svg);
  }

  &.B {
    background-image: url(../../../images/level2.svg);
  }

  &.C {
    background-image: url(../../../images/level3.svg);
  }
}

.card-evaluation {
  width: 15px;
  height: 15px;
  background: no-repeat center;
  background-image: url(../../../images/evaluation_white.svg);
}

.card-translation {
  width: 18px;
  height: 18px;
  background: no-repeat center;
  background-image: url(../../../images/translation_white.svg);
}

.card-pc-preferred {
  width: 18px;
  height: 18px;
  background: no-repeat center;
  background-image: url(../../../images/desktop_white.svg);
}

.card-title {
  margin: 0;
  font-size: 1.125rem;

  @include mq($from: md) {
    font-size: 1.25rem;
  }
}

.card-body {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  //justify-content: flex-end;
  padding: 2rem;
}

.card-footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 1rem 2rem;
  border: 0;
  background: none;
  font-size: 1rem;
  font-weight: 700;

  &:before {
    content: "";
    height: 3px;
    position: absolute;
    left: 2rem;
    top: -0.75rem;
    width: 30%;
    background: #B4C642;
    transition: width 1s ease;
  }
}

.card-footer-icon {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 2rem 2rem 2rem;
  border: 0;
  background: none;
  font-size: 1rem;
  font-weight: 700;

  div {
    margin-left: 1.5rem;
  }

  div:first-child {
    margin-left: 0;
  }
}

.card.restricted {
  .card-category {
    position: relative;
    top: 8px;
    text-transform: uppercase;
  }

  .card-body {
    padding: 1.3rem 2rem 1rem;
  }

  .card-footer {
    text-transform: uppercase;
    padding: 0.5rem 2rem 1rem;

    &:before {
      display: none;
    }
  }
}

.card-type {
  width: 40px;
  height: 40px;

  min-width: 40px;

  float: left;

  margin-right: 10px;

  background: no-repeat center;

  &.exercise,
  &.anone,
  &.cloze,
  &.krizovka,
  &.kviz,
  &.pretahovani,
  &.trideni,
  &.pexeso,
  &.flashcards,
  &.timeline,
  &.chatbot,
  &.diktat,
  &.oznacovanitextu,
  &.algoritmizace,
  &.tangram,
  &.oznacovaniobrazku,
  &.sifrovani,
  &.klaviatura,
  &.myslenkovamapa,
  &.webapp {
    background-image: url(../../../images/type_game.svg);
  }

  &.multimedia,
  &.video {
    background-image: url(../../../images/type_video.svg);
  }

  &.audio {
    background-image: url(../../../images/type_audio.svg);
  }

  &.pdf {
    background-image: url(../../../images/type_pdf.svg);
  }

  &.odkaz {
    background-image: url(../../../images/type_link.svg);
  }

  &.text,
  &.picture {
    background-image: url(../../../images/type_text.svg);
  }

  &.verify {
    background-image: url(../../../images/type_test.svg);
  }

  &.model3d {
    background-image: url(../../../images/type_3d.svg);
  }

  &.geogebra {
    background-image: url(../../../images/type_geogebra.svg);
  }
}

.card-level {
  position: relative;
  display: block;
  margin: 1rem 0 0 0;
  padding: 0.375rem 0 0.375rem 28px;

  &:before {
    content: "";
    width: 17px;
    height: 21px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -13px;
    background: url(../../../images/stopwatch.svg) no-repeat center;
    background-size: contain;
  }
}


.card-deck {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  margin-right: -10px;
  margin-left: -10px;

  z-index: 1;

  .card {
    min-width: 220px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;

    &:not(.card--blank):hover {
      background-color: white;

      .card-header, .card-body, .card-footer {
        color: #3A3A3A;
      }

      .card-type {
        &.exercise,
        &.anone,
        &.cloze,
        &.krizovka,
        &.kviz,
        &.pretahovani,
        &.trideni,
        &.pexeso,
        &.flashcards,
        &.flashcards,
        &.timeline,
        &.chatbot,
        &.diktat,
        &.oznacovanitextu,
        &.algoritmizace,
        &.tangram,
        &.oznacovaniobrazku,
        &.sifrovani,
        &.klaviatura,
        &.myslenkovamapa,
        &.webapp {
          background-image: url(../../../images/type_game_3A3A3A.svg);
        }

        &.multimedia,
        &.video {
          background-image: url(../../../images/type_video_3A3A3A.svg);
        }

        &.audio {
          background-image: url(../../../images/type_audio_3A3A3A.svg);
        }

        &.pdf {
          background-image: url(../../../images/type_pdf_3A3A3A.svg);
        }

        &.text,
        &.picture {
          background-image: url(../../../images/type_text_3A3A3A.svg);
        }

        &.verify {
          background-image: url(../../../images/type_test_3A3A3A.svg);
        }

        &.model3d {
          background-image: url(../../../images/type_3d_3A3A3A.svg);
        }

        &.geogebra {
          background-image: url(../../../images/type_geogebra_3A3A3A.svg);
        }
      }

      .card-difficulty {

        &.A {
          background-image: url(../../../images/level1_3A3A3A.svg);
        }

        &.B {
          background-image: url(../../../images/level2_3A3A3A.svg);
        }

        &.C {
          background-image: url(../../../images/level3_3A3A3A.svg);
        }
      }

      .card-evaluation {
        background-image: url(../../../images/evaluation.svg);
      }

      .card-translation {
        background-image: url(../../../images/translation.svg);
      }

      .card-pc-preferred {
        background-image: url(../../../images/desktop.svg);
      }

      &:before {
        display: none;
      }
    }
  }


  @include mq($from: lg) {
    .card-title {
      font-size: 1.375rem;
    }
  }

  @include mq($from: xl) {
    .card-title {
      font-size: 1.5rem;
    }
  }

  .sizer {
    flex: 0 0 calc(100% / 12);
    width: calc(100% / 12);
    height: 1px;
  }
}

.card-deck--compact {
  margin: 0;
  width: 100%;
  justify-content: center;

  .card-deck-layout {
    max-width: 1920px;
    width: 100%;
    padding: 0 10px;
    display: grid;
    justify-items: center;
    margin: 1.5rem 0;

    @include mq($from: md) {
      grid-template-columns: 33% 33% 33%;
    }

    @include mq($from: xl) {
      grid-template-columns: 25% 25% 25% 25%;
    }

    .card {
      width: calc(100% - 20px);
      margin: 10px;
      min-height: 200px;

    }

    @include mq($from: xs, $until: md) {
      .card--blank {
        display: none;
      }
    }

    @include mq($from: md, $until: xl) {
      .card--blank:nth-child(n + 10) {
        display: none;
      }
    }

  }
}

.card-group--sm,
.card-group--md {
  display: flex;
  flex-flow: row wrap;

  .card {
    flex: none;
    margin-right: 10px;
    margin-bottom: 10px;
    min-height: 140px;
    width: 140px;
  }


  .card-header {
    padding: 1.25rem 1rem 0 1rem;
  }

  .card-body {
    padding: 1.25rem 1rem;
  }
}

.card-group {
  .card--exercises {
    border-radius: 20px !important;
  }
}

.card-group--md {

  @include mq($from: lg) {
    .card {
      min-height: 200px;
      width: 200px;
    }

    .card--exercises {
      min-height: 170px;
      width: 170px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .card-title {
      font-size: 1.34rem;
      margin-top: 2.25rem;
    }
  }

  @include mq($from: xl) {
    .card {
      margin-right: 30px;
      margin-bottom: 30px;
    }

    .card--exercises {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.card--more {
  min-height: 268px;
  background-color: #B4C642;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  background-image: url(../../../images/pixel-left.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  color: $body-color;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 75%;
    width: 36px;
    height: 36px;
    margin: -18px 0 0 -18px;
    background: url(../../../images/more.svg) no-repeat;
    background-size: contain;
    transition: transform .3s;
  }

  .card-body {
    line-height: 1.2;
    justify-content: center;
  }

  &:hover {
    color: $body-color;

    &:after {
      transform: rotate(180deg);
    }

    .card__count {
      opacity: 0.15;
    }
  }

  @include mq($from: lg) {
    font-size: 2rem;
  }
}

.card__count {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0.05;
  transition: opacity .3s;
  font-size: 160px;
}

.card--seen {
  .card-bg {
    background: url(../../../images/seen.svg) no-repeat right 100px;
  }
}

.card--break {
  flex-basis: 100%;
  height: 0;
}

.card--bordered {
  border-radius: 20px !important;

  &:hover {
    &:before {
      border-radius: 20px !important;
    }
  }
}

.card-product {
  min-height: 100%;

  .card-img {
    a {
      background-color: #fafafa;
      height: 250px;
    }

    img {
      height: 250px;
    }
  }

  .card-footer {
    &:before {
      height: 1px;
      left: 0;
      width: 100%;
      transition: none;
      background: #f4f5f5;
    }
  }
}