.section.section--intro {
  padding: 0;
  max-width: 1920px;
  margin: 0 auto;

  #search-autocomplete {
    min-height: 170px;
  }

  .search-inline {
    flex-direction: column;
  }

  form.search-inline:hover {
    width: 102%;
  }

  &.logged-user {
    .intro {
      background: linear-gradient(90deg, rgba(255,206,0,1) 0%, rgba(255,179,0,1) 50%, rgba(255,206,0,1) 100%);
      padding: 2rem 0 3rem;
      height: auto;

      .intro__scroll-to-content {
        bottom: -3rem;
      }

      @include mq($from: lg) {
        padding: 0;
        height: calc(100vh - 150px);

        .intro__scroll-to-content {
          bottom: -30px;
          margin-top: 0;
        }
      }

    }

    .lottie-animation {
      max-height: 33vh;
      display: flex;
      flex-direction: column;

      @include mq($from: lg) {
        position: absolute;
        left: 30%;
        max-height: 100%;
        width: 40%;
      }
    }

    .search-block, .recommendation-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      text-align: center;
      padding: 0 8%;
      position: relative;
      z-index: 11;
      flex: 1 1 50%;

      .btn-search--dark {
        font-size: 15px;

        @include mq($from: lg) {
          font-size: 17px;
        }

        svg {
          position: relative;
          top: -2px;
        }
      }

      strong {
        color: white;
        text-transform: uppercase;
      }

      h1 {
        text-align: center;
        margin-top: 0;
      }

      @include mq($from: lg) {
        .search-box.focus .search-inline--lg {
          left: -5vw;
        }
      }

      @media (min-width: 1921px) {
        .search-box.focus .search-inline--lg {
          left: -55px;
        }
      }

      form.search-inline.with-start:after {
        display: none;
      }

      #search-autocomplete {
        min-height: 55px;
      }
    }

    .recommendation-block {
      z-index: 8;
    }

    .btn-brick, .btn-secondary {
      border-radius: 2rem;
      font-weight: 800;

      @include mq($from: lg) {
        padding: 1rem 1.5rem;
      }

      .badge {
        background-color: #6291D2!important;
        text-transform: uppercase;
        padding: 5px 8px;
        margin-left: 10px;

        @include mq($from: lg) {
          padding: 10px 8px;
        }
      }
    }
  }
}

.intro {

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../images/pixel-left-hard.png), url(../../../images/pixel-right-hard.png);
    background-repeat: no-repeat, no-repeat;
    background-position: 0 0, 100% 100%;
  }

  margin: 0 1rem;
  border-radius: 20px;
  background: linear-gradient(270deg, #FFB300, #FFCE00);
  height: calc(100vh - 150px);
  max-height: 1024px;
  position: relative;

  @include mq($from: md) {
    margin: 0 30px;
    padding: 0 20px;
  }

  .row {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  .intro-overflow {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: $yellow;
    @include gradient-bg(rgba(255, 193, 7, 1));
    border-radius: 20px;
  }

  &.overflow {
    .intro-overflow {
      display: block;
    }
  }

  .btn-search--dark {
    position: relative;
    z-index: 11;
  }

  .recommendation-activities {
    position: relative;
    z-index: 8;
  }

  &.doodle {
    background: linear-gradient(270deg, #FFCE00, #FF824B);

    @include media-breakpoint-up(lg) {
      .search-box.focus .search-inline--lg {
        left: -40vw;
      }
    }

    @media (min-width: 1921px) {
      .search-box.focus .search-inline--lg {
        left: -750px;
        top: -170px;
      }
    }
  }
}

.intro h1 {
  @include media-breakpoint-up(md) {
    padding-left: 36px;
  }
}

#intro__balloon {
  width: 100%;
  max-width: 500px;
  max-height: calc(100vh - 150px);
  background-image: url(../../../images/balon_stin.png);
  background-size: 100% 100%;
  position: relative;
  z-index: 2;

  canvas {
    position: absolute;
    top: 0;
    left: -5%;
  }

  .webgl-placeholder {
    position: relative;
    top: 50px;
    left: 5%;

    width: 90%;
    max-width: 335px;
    padding-bottom: 120%;

    @include media-breakpoint-up(lg) {
      left: 5%;
    }
  }

  @include media-breakpoint-up(sm) {
    display: block;
  }
}

#intro__doodle {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (-o-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
    aspect-ratio: 1.5;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-device-pixel-ratio: 4/2),
  only screen and (min-device-pixel-ratio: 2) {
    aspect-ratio: 2;
  }
}

.intro__head {
  @include container();
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;

  .container {
    padding: 120px 15px;
  }

  h1,
  h1:first-child {
    margin: 0 0 1.25em 0;
    color: #fff;
    font-size: 2rem;
    text-align: center;
  }

  @include mq($from: sm) {
    text-align: left;

    .container {
      padding: 50px 15px 150px 40%;
    }


    h1,
    h1:first-child {
      margin: 3.375rem 0 1em 0;
      font-size: 1.5rem;
      text-align: left;
    }
  }

  @include mq($from: md) {

    h1,
    h1:first-child {
      font-size: 2.5rem;
    }
  }
}

.btn-profile {
  border: 0;
  font-size: 1.125rem;
  color: white;
  background-color: #BD5353;
  box-shadow: none;
}

.intro__form {
  position: relative;
  max-width: 460px;

  .btn {
    border: 0;
    background-color: #B4C642;
    font-size: 1.125rem;
    color: $body-color;
    box-shadow: inset 0 -3px 0 #92A135, 5px 5px 15px rgba(0, 0, 0, 0.1);
  }

  .form-inline {
    padding: 3px;
    border-radius: $border-radius;
    background: #fff;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  }

  .form-control {
    flex: 1;
    width: 100%;
    border: 0;
    padding: 0.8125rem 0 0.8125rem 50px;
    font-size: 0.9375rem;
    outline: transparent;

    &::-webkit-input-placeholder {
      font-style: italic;
      color: #3A3A3A
    }

    &::-moz-placeholder {
      font-style: italic;
      color: #3A3A3A;
    }

    &:-ms-input-placeholder {
      font-style: italic;
      color: #3A3A3A;
    }

    &:-moz-placeholder {
      font-style: italic;
      color: #3A3A3A;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 43%;
    bottom: -80px;
    width: 170px;
    height: 62px;
    background: url(../../../images/start-here.svg) no-repeat;
    background-size: contain;

    @include mq($from: sm) {
      left: auto;
      right: 80px;
      bottom: -80px;
    }

    @include mq($from: lg) {
      right: -40px;
      bottom: -120px;
    }
  }

  &.profile {
    max-width: 720px;

    .btn {
      color: white;
      background-color: #BD5353;
      box-shadow: none;
    }

    &.focus {
      & > .intro__form--content {
        transform: translateX(calc(-0.2 * 1170px + 10vw - (100vw - 1170px) / 2));
      }
    }

    &:after {
      display: none;
    }
  }

  &.focus {
    .hint {
      display: block;
      visibility: visible;
      width: 80vw;
      opacity: 1;
      z-index: 8;
    }

    form {
      width: 80vw;
    }
  }

  &.showhint {
    .hint {
      display: block;
      visibility: visible;
      opacity: 1;
    }

    form {
      width: 100%;
    }
  }

  .hint {
    display: none;
  }
}

.intro__info {
  .container {
    @include container(1280px);
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }

  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card {
    flex: 1 0 0%;
    min-height: 200px;
    margin-bottom: 0;
  }

  @include mq($from: lg) {
    .card {
      min-height: 270px;
    }
  }
}

.intro__family {
  padding: 80px 15px 60px 15px;
  background: #FE9A57 url(../../../images/intro-family.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  text-align: center;

  h2 {
    margin: 0 0 0.875em 0;
  }

  .btn {
    text-transform: uppercase;
  }

  @include mq($from: md) {
    padding: 80px 30px 60px 30px;
    text-align: left;

    p {
      max-width: 420px;
    }
  }

  @include mq($from: lg) {
    padding: 80px 30px 60px 90px;
  }
}

.intro__user {
  padding: 80px 40px 80px 40px;
  background: #FE9A57 url(../../../images/intro-user.svg) no-repeat 115% 100%;
  background-size: 50%;
  color: #fff;
  text-align: left;

  h2 {
    margin: 0 0 0.875em 0;
  }

  p {
    width: 75%;
  }

  .btn {
    text-transform: uppercase;
  }

  @include mq($from: md) {
    padding: 80px 30px 60px 30px;

    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1.25rem;
      max-width: 420px;
    }
  }

  @include mq($from: lg) {
    padding: 80px 30px 60px 90px;
  }
}

.intro__scroll-to-content {
  position: absolute;
  left: calc(50% - 60px);
  margin-top: -30px;
  z-index: 15;
}
