.layout--fullscreen {
    height: 100%;
    background: #F9E5F1;

    .head,
    .foot {
        display: none;
    }
}


.task {
    position: relative;

    @include mq($from: sm) {
        display: flex;
        flex-direction: column;

        .layout--fullscreen & {
            min-height: 100vh;
        }
    }
}

.task__question {
    background: #C3007C;
    padding: 1rem 1.25rem;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);

    @include mq($from: sm) {
        padding-right: 150px;
    }
}

.task__nav {
    padding: 15px 15px 60px 15px;

    @include mq($from: sm) {
        flex: 1 0 0%;
        display: flex;
    }
}

.task__circle {
    margin: auto;
    background: #F9E5F1;

    @include mq($from: sm) {
        width: 420px;
        height: 420px;
        border-radius: 50%;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    }
}

.task__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0.5rem 0;
    }

    @include mq($until: sm) {
        li {
            position: static;
            transform: none !important;
        }
    }

    @include mq($from: sm) {
        position: relative;
        top: 50%;
        left: 50%;
        width: 0;

        li {
            position: absolute;
            width: 100px;
            margin: -8px 0 0 -50px;
            transition: all 1s linear;
        }

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 150px;
            height: 150px;
            margin: -75px 0 0 -75px;
            background: url(../../../images/task.svg) no-repeat center;
            box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
            border-radius: 50%;
        }
    }
}

.task__link {
    display: block;
    width: 100%;
    padding: 0.75rem;
    color: #000;
    text-align: center;
    text-decoration: none;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
    border-radius: 0.5rem;
    background: #fff;

    @include mq($from: sm) {
        padding: 0.375rem 0.5rem;
    }

    &:hover {
        text-decoration: none;
        background: linear-gradient(to bottom, #f6f6f6, #eee);
        color: #C3007C;
    }
}

.task__actions {
    border-bottom: 1px solid #F7E4EF;
    background: #C3007C;
    text-align: center;

    a {
        display: inline-block;
        vertical-align: middle;
        padding: 10px;
        text-decoration: none;
    }

    @include mq($from: sm) {
        border: 0;
        position: absolute;
        right: 10px;
        top: 5px;
    }
}

.task__results {
    width: 100%;
    display: flex;
    max-width: 980px;
    padding: 10px 30px;
    margin: auto;
    justify-content: space-between;
    font-size: 1.125rem;
    font-weight: 700;
}

.task__ok {
    color: #68B42D;
}

.task__ko {
    color: #FF0000;
}