.class {
    position: relative;
    overflow: hidden;
    margin-top: -3.5rem;
    padding-top: -3.5rem;

    @include mq($from: xs) {
        margin-top: -4rem;
        padding-top: 4rem;
    }

    @include mq($from: md) {
        margin-top: -4.5rem;
        padding-top: 4.5rem;
    }
}

.class__no {
    position: absolute;
    left: 0;
    font-size: 90px;
    line-height: 60px;
    top: -5px;
    font-weight: 700;

    @include mq($from: xs) {
        font-size: 180px;
        line-height: 120px;
        top: -15px;
    }

    @include mq($from: lg) {
        font-size: 300px;
        line-height: 200px;
        top: -25px;
    }
}