.navbar-sub {
  max-width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  display: none;
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  background: #F8F9FA url(../../../images/pixel-sm.png) no-repeat 0 0;

  @include mq($from: md) {
    background: #fff;
    border-top: 1px solid #CEE9E9;
    padding-top: 60px;
  }

  @media (min-width: 1920px) {
    position: fixed;
    left: 0;
    right: 0;
    top: $navHeightUnder1920;
  }

  .container {
    justify-content: center;
  }

  .subheader {
    font-size: 15px;
    color: #3A3A3A;
    text-transform: uppercase;
    margin-bottom: 14px;
  }
}

.foot .navbar-sub {
  position: fixed;
  top: 0;
}

.foot .navbar-sub:after {
  pointer-events: auto!important;
}

@include mq($from: md) {
  .nav-item--sub {
    &:hover {
      .navbar-sub {
        display: block;
        animation: fadeIn .5s;
      }
    }
  }
  .navbar-sub::before {
    content: " ";
    width: 100%;
    height: 24px;
    position: absolute;
    left: 0;
    top: -24px;
  }
  //.navbar-sub:after {
  //  content: " ";
  //  position: absolute;
  //  z-index: 50;
  //  top: 100%;
  //  left: 0;
  //  right: 0;
  //  height: calc(100vh - 114px);
  //  width: 100%;
  //  //margin-left: -60px;
  //  //margin-right: -60px;
  //  background-color: rgba(58, 58, 58, 0.6);
  //  pointer-events: none;
  //}
  .navbar-sub {
    .container .row {
      max-width: 1100px;
      margin: auto;
    }
    .header-search-area {
      margin-top: 60px;
      padding: 30px 0;
      margin-left: -60px;
      margin-right: -60px;
      background-color: #E6E6E6;
    }
  }
}
