.badgeBody {
  padding: 2rem 0;
  margin-bottom: 2rem;
  background: #F7F7F7;
  letter-spacing: 0;
  color: #3A3A3A;
  p {
    font-size: 20px;
    max-width: 600px;
  }
  @include media-breakpoint-up(lg){
    padding: 1.4rem 1rem;
    p {
      padding-left: 2rem;
    }
  }
}
.badge--img {
  border-radius: 50%;
  box-shadow: 0 1.25rem 5rem rgba(0, 0, 0, 0.25);
  width: 10rem;
}
.badge--img-abs {
  position: absolute;
  border-radius: 50%;
  width: 7.5rem;
  right: 2rem;
  top: 30%;
  box-shadow: 0 1.25rem 5rem rgba(0, 0, 0, 0.25);
  @include media-breakpoint-up(sm){
    width: 10rem;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

