.page {
  display: flex;
  flex-direction: column;
  //Ponechány obě varianty z důvodu zpětné kompatibility
  min-height: 100vh;
  min-height: 100svh;

  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}


main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include clearfix();

  img {
    max-width: 100%;
    height: auto;
  }

  @include mq($until: md) {
    padding-top: 15px;
  }


  &.bg-gray {
    background-color: #F5F5F5;
  }

  section {
    width: 100%;
  }
}

.layout--home {
  head {
    border-bottom: 0;
  }

  h2, .h2 {
    font-size: 1.875rem;

    @include mq($from: md) {
      font-size: 2.25rem;
    }

    @include mq($from: lg) {
      font-size: 2.5rem;
    }
  }
}

html {
  height: 100%;
}

.page__overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
}

.fixed-behind {
  position: relative;
  z-index: 99;
}

.border-radius-0 {
  border-radius: 0;
}

.mw-1170px {
  max-width: 1170px;
}

.mw-lg-400px {
  @include media-breakpoint-up(md) {
    max-width: 400px;
  }
}

.page-container {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @include media-breakpoint-up(md) {
    padding-left: 35px;
    padding-right: 35px;
  }

  @include media-breakpoint-up(xl) {
    padding-left: 130px;
    padding-right: 130px;
  }
}

@include media-breakpoint-up(xl) {
  .pr-page-quarter {
    padding-right: 32px!important;
  }

  .pl-page-quarter {
    padding-left: 32px!important;;
  }
}

@include media-breakpoint-up(lg) {
  .pl-section {
    padding-left: 100px;
  }

  .pr-section {
    padding-right: 100px;
  }
}


@include media-breakpoint-up(lg) {
  .pl-section {
    padding-left: 100px;
  }

  .pr-section {
    padding-right: 100px;
  }
}

.bg-editionSsn {
  background-color: $editionSsn;
}

.bg-hybrid {
  background-color: $hybrid;
}

.bg-merchSsn {
  background-color: $merchSsn;
}

.w-200px {
  width: 200px;
}