.section--hybrid-intro {
    .book__desc {
        p {
            font-size: 1.25rem;
        }
    }

    @include mq($from: lg) {
        padding-bottom: 2.5rem !important;

        .book--wide {
            max-width: 1280px;
            padding-left: 65px;
            padding-right: 65px;
        }

        .book__img--double {}

        .book__img {
            margin-left: 215px;
            margin-bottom: 30px;
        }

        .book__desc {
            max-width: 537px;
        }
    }
}

.hybrid__new {
    cursor: initial !important;
    margin: 0;
    color: #3A3A3A;
    padding: 3px 10px;
    margin-top: -15px;
    text-align: center;
    font: normal normal bold 12px Source Sans Pro;
    text-transform: uppercase;
}

.hybrid_link {
    color: white;
    border: 1px solid white;
    border-radius: 5px;

    &:after {
        content: "";
        float: right;
        margin-right: -15px;
        margin-left: 10px;
        width: 20px;
        height: 20px;
        background: url(../../../images/box-arrow-right-01.svg) no-repeat center;
        background-size: contain;
    }

    &:hover {
        &:after {
            filter: invert(1);
            transition: 0.15s;
        }
    }
}

.hybrid_publication_nav_list {
    list-style-type: none;
    line-height: 45px;
    width: 60%;

    li {
        padding-top: 25px;
        position: relative;
        font-size: 16px;
        color: #3A3A3A;
        font: normal normal normal 16px/25px Source Sans Pro;
    }

    li::before {
        display: block;
        position: absolute;
        left: -2.5em;
        content: ' ';
        background: url(../../../images/navbar_hybrid_list_style_img_32x32.png) no-repeat;
        width: 32px;
        height: 32px;
    }
}

.hybrid_publication_intro_item {
    padding-top: 20px;
    padding-bottom: 20px;

    img {
        float: left;
        width: 52px;
        height: 52px;
    }

    p {
        padding-left: 70px;
        font: normal normal bold 18px Source Sans Pro;
    }
}