.fancybox-container {
    font-family: $font-family-base;
}

.fancybox-close-small {
    width: 60px;
    height: 60px;
}

.fancybox-filter {
    .fancybox-slide {
        padding: 0;

        &:before {
            content: normal;
            display: none;
        }
    }


    .fancybox-content {
        margin: 0;
    }
}

.fancybox-slide--html .fancybox-content {
    padding-top: 45px;
    padding-bottom: 30px;
}