.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 14px;

  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 27px;
  }
}

.text-19 {
  font-size: 17px;

  @include media-breakpoint-up(lg) {
    font-size: 19px;
    line-height: 27px;
  }
}

.text-20 {
  font-size: 20px;
  line-height: 30px;
}

.text-22 {
  font-size: 18px;
  line-height: 27px;

  @include media-breakpoint-up(lg) {
    font-size: 22px;
    line-height: 32px;
  }
}

.text-30 {
  font-size: 22px;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }
}

.text-italic {
  font-style: italic;
}