.shops {
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  overflow: hidden;

  .shop {
    display: flex;
    width: 100%;
    padding: 1rem 2rem;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;

    .logo {
      flex-grow: 1;
      padding-right: 1rem;

      img {
        @include media-breakpoint-up(md) {
          max-width: 50%;
        }
      }
    }

    .shop-button {
      display: flex;
      flex: 0 0 auto;
      align-content: center;
      flex-wrap: wrap;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}