.result-list {
    @include mq($until: md) {
        .result:nth-child(n+5) {
            display: none;
        }
    }
}

.result-more {
    margin-top: 1.5rem;

    @include mq($from: md) {
        display: none;
    }
}

.result-list--active {
    .result-more {
        display: none;
    }

    .result:nth-child(n+5) {
        display: flex;
    }
}

.result {
    display: flex;
    width: 100%;
    max-width: 285px;
    border-bottom: 1px solid #CEE9E9;
    padding: 0.75rem 0;
}

.result__img {
    width: 65px;
    min-width: 65px;

    .icon {
        width: 50px;
        height: 50px;
    }
}

.result__title {
    display: block;
    margin: 0 0 3px 0;
    font-weight: 700;
    font-size: 0.9375rem;
    text-decoration: none;
    color: $body-color;
}

.result__content {
    flex: 1 0 0%;
    padding-left: 0.5rem;
}

.result__meta {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}