.toast {
  &-header {
    & > *:not(.close) {
      font-size: 1.2rem;
      padding: 1rem;
      font-weight: bold;
    }
  }

  max-width: 100%;

  @include mq('md') {
    max-width: 450px;
  }

  &-container {
    position: fixed;
    top: 2rem;
    left: 5%;
    width: 90%;
    max-width: 90%;
    z-index: 1000;

    @include mq('md') {
      left: 50%;
      transform: translateX(-50%);
      width: unset;
      max-width: 450px;
    }
  }
}

@mixin toast-variant($background, $border, $color) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;

  hr {
    border-top-color: darken($border, 5%);
  }

  .toast-header {
    color: $color;
  }
}

//@each $color, $value in $theme-colors {
//  .toast-#{$color} {
//    @include toast-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
//  }
//}
