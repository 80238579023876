$clrText: #3a3a3a;

#user-login-navbar {
    overflow-x: scroll;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    ul{
        display: flex;
        min-width: 355px;
        margin-left: 0px;
        padding: 40px 0 40px 0;
        overflow-x: auto;

        li {
            float: left;
            // display: inline-block;
            list-style: none;
            margin-right: 10px;
        }

        li a {
            padding: 5px 15px;
            color: black;
            border-radius: 50px;
            letter-spacing: 0.9px;
            text-decoration: none;
            text-transform: uppercase;
            background-color: white;
            white-space: nowrap;
        }

        li a:hover {

            background-color: #E6E6E6;
        }

        li a.active {
            color: white;
            background-color: $clrText;
        }
    }
}

#user-login-navbar::-webkit-scrollbar {
  display: none; /* chrome */
}
