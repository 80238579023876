.recommendation {

  height: 100vh;

  @include media-breakpoint-up(md) {
    min-height: 33vh;
    height: auto;

    @media screen and (min-height: 1200px) {
      max-height: 400px;
      min-height: auto;
    }
  }

  .offcanvas-title {
    flex-basis: calc(100% - 50px);
    margin-left: 30px;
  }

  .offcanvas-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(md) {
      max-width: 295px;
    }
  }

  .btn-close {
    position: relative;
    background: none;
    opacity: 1;

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-width: 0px;
      border-style: solid;
      border-color: black;
      border-radius: 50%;
      transition: border-width .5s ease-out;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.414' height='7.414' viewBox='0 0 7.414 7.414'%3E%3Cg transform='translate(522.721 2280.845)'%3E%3Cline x2='6' y2='6' transform='translate(-522.013 -2280.138)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1'/%3E%3Cline x2='6' y2='6' transform='translate(-516.013 -2280.138) rotate(90)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E");
      background-position: center;
      background-size: 15px;
      background-repeat: no-repeat;
    }

    &:hover {
      &:before {
        border-width: 15px;
      }
    }
  }

  &.text-black {
    .btn-close {
      &:after {
        filter: invert(1);
      }

      &:hover {
        &:after {
          filter: none;
        }
      }
    }
  }

  .activity-box {
    width: 100%;

    @include media-breakpoint-up(md) {
      //width: calc(100% / 3);
      height: 100%;

      .card {
        height: 100%;
      }
    }
  }

  &.offcanvas-end {
    .activity-box {
      width: 100%!important;
    }
  }
}

.viky-animation {
  width: 32px;
  height: 32px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-right: 1rem;
  top: 8px;
  border-radius: 8px;

  //& > div {
  //  position: absolute;
  //  top: -45px;
  //  left: -59px;
  //  height: 150px!important;
  //  width: 150px!important;
  //}
}