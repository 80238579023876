.external {
    position: relative;
    padding-right: 20px;

    &:before {
        content: "";
        @include sprite(external);
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.75;
    }
}

.img-full {
    width: 100%;
    height: auto;
    display: block;
}

.img-left {
    float: left;
    margin: 0 1rem 1rem 0;
}

.img-right {
    float: right;
    margin: 0 0 1rem 1rem;
}

.publication {
    display: block;
    text-decoration: none;
    text-align: center;
    color: #3A3A3A;
    transition: color .3s;
    margin-bottom: 2rem;

    &:hover {
        color: $primary;
        text-decoration: none;

        img {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        }
    }

    img {
        display: block;
        margin: 0 auto 1rem auto;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
        transition: all .3s;
    }
}

.profile-head {
    display: flex;
    align-items: center;

    flex-wrap: wrap;

    background-color: white;
    padding: 22px 0;

    .profile-heading {
        padding: 10px 20px;

        h1 {
            display: inline;
            line-height: 36px;
            vertical-align: middle;
        }
    }
}

.back {
    display: inline-block;
    width: 36px;
    height: 36px;
    color: $body-color;
    transition: color .3s;

    margin-right: 12px;

    svg {
        fill: currentColor;
    }

    &:hover {
        color: $primary;
    }
}

.sep {
    height: 4px;
    background: url(../../../images/sep.png) repeat-x;
    overflow: hidden;
    clear: both;

    &.fixed-behind {
        z-index: 98;
    }
}

.link-more {
    color: #759BD0;

    &:hover {
        color: darken(#759BD0, 20%);
    }
}

.price {
    font-size: 1.0625rem;
    line-height: 1.2;
}

.hr-mini {
    max-width: 60px;
    margin: 1.25rem auto;
    border-top: 1px solid #707070;
}

.mark {
    padding: 1.5rem;
    color: #fff;
    font-style: italic;
    font-size: 1.25rem;
    text-align: center;
    background-color: #BF6122;

    @include mq($from: md) {
        padding: 1.5rem 4rem;
        background: url(../../../images/mark.svg) no-repeat center;
        background-size: contain;
    }
}

.subclaim {
    color: white;
    font-size: 2em;
}

.hw-30px {
    height: 30px;
    width: 30px;
}