.user {
  @include media-breakpoint-down(md) {
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .orange-color {
    color: #c67822;
  }

  .sticky-top {
    z-index: 50
  }

  .spacer {
    padding-bottom: 2rem;
    background-position: bottom center;
    background-repeat: no-repeat;
    background: linear-gradient(#F4F5F5, #FFFFFF);
  }

  .spacer-2 {
    background-color: #cee9e9;
    width: 100%;
    height: 1px;
  }

  .arrow {
    background-size: 7px 12px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    &--left {
      background: url(../../../images/arrowL.png);
    }

    &--right {
      background: url(../../../images/arrowR.png);
    }
  }

  .shadow {
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.16) !important;
    background: white;
    border-radius: 10px;
  }

  .br-10 {
    border-radius: 10px;
  }

  .card {
    border-radius: 10px;
    padding: 40px 50px;

    @include media-breakpoint-down(md) {
      padding-bottom: 20px;
    }
  }

  .form-group label {
    color: #3A3A3A;
    font-weight: 700;
    font-size: 16px;
    display: block;
  }

  .form-control {
    text-indent: .4rem;
    height: auto;
    border-radius: 5px;

    &:not(.is-invalid) {
      padding: .8rem .5rem;
      color: #707070;
      background: #F4F5F5;
      border: 1px solid #D5D5D5;
    }

    &.is-invalid {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      padding-left: 0.5rem;
    }
  }

  .form-control::placeholder {
    color: #d3d3d3
  }

  .button {
    cursor: pointer;
    border: transparent;
    text-decoration: none !important;
    outline: none !important;
    border-radius: 0.313rem;
    padding: .8rem 2rem;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 700;
    display: block;
    width: 100%;
    margin: .5rem 0;
    text-align: center;
    transition: all 300ms;

    &--login {
      background-color: #BA4A4A;

      &:hover {
        background-color: darken(#BA4A4A, 20%);
      }
    }

    &--facebook {
      background-color: #3D589F;
      background-image: url(../../../images/fb.svg);
      background-repeat: no-repeat;
      background-position: 1rem;

      &:hover {
        background-color: darken(#3D589F, 20%);
      }
    }

    &--google {
      background-color: #006FEA;
      background-image: url(../../../images/google.svg);
      background-repeat: no-repeat;
      background-position: 1rem;

      &:hover {
        background-color: darken(#006FEA, 20%);
      }
    }

    &--microsoft {
      color: #3a3a3a;
      border: 0.063rem solid #b8b8b8;
      background-color: #ffffff;
      background-image: url(../../../images/microsoft.svg);
      background-repeat: no-repeat;
      background-position: 1rem;

      &:hover {
        background-color: darken(#b8b8b8, 5%);
      }
    }

    &--email {
      background-color: #ff824b;
      background-image: url(../../../images/e-mail.svg);
      background-repeat: no-repeat;
      background-position: 1rem;

      &:hover {
        background-color: darken(#ff824b, 20%);
      }
    }

    &--orange {
      background-color: #ff824b;

      &:not(:disabled):hover {
        background-color: darken(#ff824b, 20%);
      }
    }

    &--grey {
      color: #3a3a3a;
      background: #d5d5d5;
      min-width: 11.188rem;
      text-align: center;

      &:hover {
        color: white;
        background: darken(#d5d5d5, 20%);
      }
    }

    &--blue {
      background: #008EBE;
      color: white !important;
      display: inline-block;
      width: auto;
      text-decoration: none;
      transition: all 300ms;

      &:hover {
        background: darken(#008EBE, 10%);
      }
    }

    &--clear {
      background: unset;
      border: 0.063rem solid #bcbcbc;
      display: inline-block;
      width: auto;
      padding: .6875rem 1rem;
      color: #3a3a3a;
      font-size: 0.938rem;
      font-weight: 400;

      &:hover {
        background: darken(#bcbcbc, 2%);
      }
    }
  }

  .setting__other {
    .button {
      &--orange {
        min-width: 11.188rem;
        text-align: center;
      }
    }
  }

  .pagination {
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    @include media-breakpoint-down(md) {
      margin: 10px 0;
    }

    li {
      margin: 2px;
    }

    a {
      min-width: 40px;

      @include media-breakpoint-down(sm) {
        min-width: 30px;
      }

      min-height: 40px;
      display: inline-block;
      text-align: center;
      padding: 8px;
      border-radius: 0.313rem;
      background-color: #d5d5d5;
      font-size: 0.938rem;
      font-weight: 700;
      color: #3a3a3a;
      text-decoration: none;

      &:hover:not(.dots):not(.disabled) {
        color: #ffffff;
        background-color: #ff824b;
      }
    }

    .active {
      color: #ffffff;
      background-color: #ff824b;
    }

    .dots {
      background: none;
    }

    .disabled:hover {
      cursor: default;
    }
  }
}