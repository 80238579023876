.section {
  //width: 100%;

  padding-top: 3.5rem;
  padding-bottom: 2.5rem;

  @include mq($from: sm) {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }

  @include mq($from: md) {
    padding-top: 4.5rem;
    padding-bottom: 3.5rem;
  }
}

.section__title {
  margin-bottom: 2rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.section--heading {
  max-height: none;
  overflow: hidden;

  @include mq($from: md) {
    max-height: 24.625rem;
  }
}

.title {
  @extend .section__title;
  margin-bottom: 1rem;
  color: $body-color;
}

.section--full {
  .container {
    max-width: none;
    padding: 0;
    margin: 0;
  }
}

.section--activity {
  background: #7C9FD1;

  padding: 0;

  min-height: 500px;

  & > .container {
    max-width: 100%;
    margin: 0 !important;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  display: flex;
  flex-grow: 1;
}

.section--activity-pause {
  background: #7C9FD1 url(../../../images/task-pause.png) no-repeat top;
}

.section--bb {
  border-bottom: 1px solid #CEE9E9;
}

.section--bt {
  border-top: 1px solid #CEE9E9;
}

section.overlay {
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.4;
    z-index: 3;
  }
}

section.section--pixel {
  background-image: url(../../../images/pixel-left.png), url(../../../images/pixel-right.png);
  background-position: 0 0, 100% 100%;
  background-repeat: no-repeat, no-repeat;
}

section.section--pixel-hard {
  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../images/pixel-left-hard.png), url(../../../images/pixel-right-hard.png);
    background-repeat: no-repeat, no-repeat;
    background-position: 0 0, 100% 100%;
    pointer-events: none;
  }
}

div.section--pixel {
  background-image: url(../../../images/pixel-left.png);
  background-position: 0 0;
  background-repeat: no-repeat;
}

.section--pixels {
  background-image: url(../../../images/pixel-lg.png);
  background-repeat: no-repeat;
  background-position: top;
}

.section--pixel-sm {
  background: #F8F9FA url(../../../images/pixel-sm.png) no-repeat 0 0;
}

.section--slider {
  padding: 0;
  background: #4773B1;
  text-align: center;

  .container {
    max-width: 1280px;
    padding: 0;
  }

  h2,
  h2:first-child {
    margin-top: 1em;
    color: #fff;
  }

  .col-md-6 + .col-md-6 {
    z-index: 2;
  }

  @include mq($from: md) {
    padding: 0 0 50px 0;
    text-align: left;
    background: #4773B1;

    h2,
    h2:first-child {
      margin-top: 2em;
    }

    .col-md-6:first-child {
      &:before {
        content: "";
        height: 1px;
        width: 80px;
        position: absolute;
        left: -60px;
        top: 154px;
        background: #fff;

      }
    }
  }

  @include mq($from: lg) {
    .col-md-6:first-child {
      &:before {
        top: 170px;
      }
    }
  }
}


.section--f7f7f7 {
  background-color: #f7f7f7;
}

.section--light {
  background-color: $light;
}

.section--gray {
  background-color: $gray;
}

.section--aquamarine {
  background-color: $aquamarine;
  color: #fff;
}

.section--aquadark {
  background-color: $aquadark;
  color: #fff;
}

.section--frog {
  @include gradient-x(#FFCE00, #FFB300);
  position: relative;
  margin: 0 auto;
}

.section--frog-old {
  background-color: #b4c642;
}

.section--green {
  background-color: $green;
  color: #fff;
}

.section--grass {
  background-color: $grass;
  color: #fff;
}

.section--blue {
  background-color: $blue;
  color: #fff;
}

.section--orange {
  background-color: $orange;
  color: #fff;
}

.section--ocher {
  background-color: $ocher;
  color: #fff;
}

.section--brick {
  background-color: $brick;
  color: #fff;
}

.section--navy {
  background-color: $navy;
  color: #fff;
}

.section--brown {
  background-color: $brown;
  color: #fff;
}

.section--sand {
  background-color: $sand;
  color: #fff;
}

.section--water {
  background-color: $water;
  color: #fff;
}

.section--yellow {
  background-color: $yellow;
}

.section--cyan {
  background-color: $cyan;
}

.section--dust {
  background-color: $dust;
}

.section--paleblue {
  background-color: $paleblue;
  color: #fff;
}

.section--darkorange {
  background-color: $darkorange;
  color: #fff;
}

.section--lighterblue {
  background-color: $lighterblue;
}

.section--hybrid {
  background-color: $hybrid;
  color: #fff;
  background-image: url(../../../images/pixel-left-a05.png), url(../../../images/pixel-right-a05.png) !important;
}

.section--recommendations {

  padding-top: 2rem;
  padding-bottom: 2rem;

  .viky-face {
    width: 32px;
    height: 32px;
  }

  h2 {
    font-size: 18px;

    @include mq($from: lg) {
      font-size: 22px;
    }
  }

  .collapse-title {
    cursor: pointer;

    img, strong {
      opacity: 1;
      transition: opacity .5s ease-out;
    }

    .btn-close {
      position: absolute;
      right: 0;
      opacity: 0;
      transition: opacity .5s ease-out;
    }

    &:not(.collapsed) {
      img, strong {
        opacity: 0;
      }

      .btn-close {
        opacity: 1;
      }
    }

    @include mq($from: lg) {
      pointer-events: none;
      cursor: initial;
    }
  }

  background-color: #F0F0F0;

  .activity-box {
    width: 100%;

    @include mq($from: lg) {
      width: calc(100% / 3);
    }
  }

  .card {
    height: 100%;
  }

  .viky-animation {
    top: 0;
    height: 40px;
    width: 40px;

    & > div {
      top: -41px;
      left: -54px;
    }
  }
}

.text-green {
  color: $green;
}

.text-grass {
  color: $grass;
}

.text-blue {
  color: $blue;
}

.text-lightblue {
  color: #008EBE;
}

.text-orange {
  color: $orange;
}

.text-ocher {
  color: $ocher;
}

.text-yellow {
  color: $yellow;
}

.text-brick {
  color: $brick;
}

.text-navy {
  color: $navy;
}

.text-brown {
  color: $brown;
}

.text-sand {
  color: $sand;
}

.text-aquamarine {
  color: $aquamarine;
}


.section--orange {
  a:not(.btn):hover {
    color: #fff;
  }
}

.section--task {
  padding: 0;
  background: #F9E5F1;

  .container {
    padding-top: 0;
  }
}

.section--shift {
  position: relative;
  z-index: 1;
  padding-top: 0;

  @include mq($from: md) {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 50%;
      height: 110px;
      top: -110px;
      background-color: inherit;
    }
  }
}

@include mq($from: md) {
  .pb-shift {
    padding-bottom: 140px;
  }

  .mt-shift {
    margin-top: -55px;
  }

  .pt-shift {
    padding-top: 90px;
  }
}

.section--tests {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: calc(50% + 15px);
    right: 0;
    background: #F7F7F7;
    bottom: 0;
    height: 40%;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.section--how {
  background: #f7f7f7;

  @include mq($from: md) {
    background: #f7f7f7 url(../../../images/img_how_small.jpg) no-repeat -180px 100%;
    background-size: 800px;
  }

  @include mq($from: xl) {
    background-position: -80px 100%;
  }

  @include mq($from: 1400px) {
    background-position: 0 100%;
  }
}

.section--help {
  background: #F7F7F7;

  @include mq($from: md) {
    background: #F7F7F7 url(../../../images/img_help.png) no-repeat -180px 100%;
    background-size: 70% auto;
  }

  @include mq($from: lg) {
    background-size: 65% auto;
  }

  @include mq($from: xl) {
    background-size: auto;
    background-position: -80px 200%;
  }
}

.section--who {
  position: relative;

  @include mq($from: md) {
    &:after {
      content: "";
      position: absolute;
      width: 50%;
      right: 0;
      top: 0;
      height: 100%;
      background: url(../../../images/desky.jpg) no-repeat center;
      background-size: cover;
    }
  }
}

.section--solution {
  p {
    font-size: 1.1rem;
  }
}

.section--individual {
  @include mq($from: md) {
    padding-top: 13rem;
  }

  .container {
    position: relative;

    .desk-mockup {
      width: 64%;
      max-width: 640px;

      @include mq($from: md) {
        position: absolute;

        left: -64px;
        bottom: -64px;
      }
    }

    .claim {
      margin-top: 4rem;

      @include mq($from: md) {
        position: relative;

        top: -64px;

        margin-top: 0;
      }
    }
  }
}

.section-graduation {
  .heading2 {
    font-family: "Source Sans Pro";
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.72px;
    line-height: 46px;
    margin-top: 1em;

    &--orange {
      color: #c67822;
    }

    &--black {
      color: #3a3a3a;
    }
  }

  .slider__cell {
    position: relative;
  }

  .slider__cell .text {
    font-family: "Source Sans Pro";
    padding-top: 20px;
    color: #3a3a3a;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  .slider--publications .slider__status {
    margin-top: 15px;
  }

  .slider--publications .slider__imgs .flickity-button {
    margin-top: 0;
  }

  .slider__imgs {
    width: 320px;
    margin: 0 auto;
  }

  .slider__imgs .slider__cell,
  .slider__text .slider__cell {
    padding: 0 22px;
  }

  .slider__img {
    padding: 0;

    img {
      box-shadow: 14px 14px 15px rgba(0, 0, 0, 0.12);
    }
  }
}

.section--order {
  background-color: #e7f1f1;

  .order-card {
    background-color: #f7f7f7;
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.16);

    &__body {
      color: #3a3a3a;
      text-align: center;

      padding: {
        top: 10px;
        left: 30px;
        right: 30px;
        bottom: 30px;
      }
    }

    .price {
      font-size: 30px;
      font-weight: 700;
    }

    img {
      width: 100%;
    }

    h5 {
      font-family: "Source Sans Pro - Regular";
      font-size: 12px;
      font-weight: 400;
    }

    h3 {
      font-family: "Source Sans Pro";

      margin: {
        top: 10px;
        bottom: 20px;
      }

      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      min-height: 55px;
    }
  }
}

.section--registration_promo {
  background-color: #FE9A57;
  color: #fff;

  .intro__user {
    background: #FE9A57 url(../../../images/intro-user.svg) no-repeat 100% 100%;
    background-size: 45%;
  }
}

.section--product-hybrid {

  .carousel-item.ssn-special--primary img {
    width: 100%;
    aspect-ratio: 3/4;
  }

  .carousel-content-right {
    width: 25%;

    .book__img {
      img {
        width: 150px;
      }
    }

    .book__img--double img + img {
      left: 125px;
      top: 33px;

      @include mq($from: md) {
        left: 75px;
      }

      @include mq($from: lg) {
        left: 125px;
      }
    }

  }

  .carousel-content-left {
    width: 100%;
    margin-top: 15px;

    @include mq($from: md) {
      width: 75%;
      margin-top: 0;
    }

    .btn {
      border-radius: 25px;
    }
  }
}

.section--edition-ssn {
  .carousel {
    width: 100%;
  }
}

.section--publication-offer {
  .card {
    max-width: 100%;
    width: 100%;

    @include mq($from: lg) {
      width: calc(50% - 30px);
      margin-right: 15px;
      margin-left: 15px;
    }

    @include mq($from: xl) {
      width: calc(33% - 30px);

      &.card-product {
        width: calc(25% - 30px);
      }
    }

    .card-footer {
      &:before {
        content: "";
        height: 1px;
        position: absolute;
        left: 0;
        top: -0.75rem;
        width: 100%;
        background: $gray;
        transition: width 1s ease;
      }
    }
  }

  .slide-1 {
    display: none;
  }

  .slide-2, .slide-3, .slide-4, .slide-5 {
    display: flex;
  }

  .slide-2 {
    @include mq($from: lg) {
      display: none;
    }
  }

  .slide-3, .slide-4 {
    @include mq($from: xl) {
      display: none;
    }
  }


  .related-content-controller {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    border-radius: 100%;
    background-color: $blue;
    width: 35px;
    height: 35px;
    padding: .5rem;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.section--product-catalog {
  .carousel-btn {
    height: 43px;
    font-size: 15px;
    font-weight: 600;
    font-family: "Source Sans Pro", sans-serif;
    line-height: 10px;
  }
}

.section--partners {
  .card {
    aspect-ratio: 1;

    .card-body {
      justify-content: space-between;
      padding-bottom: 0;
      text-align: center;

      @include mq($from: md) {
        justify-content: space-evenly;
      }
    }

    @include mq($from: md) {

      .partner-logo {
        display: flex;
        height: 35%;

        img {
          width: 50%;
          transition: .2s ease-in-out width;
        }
      }

      .d-on-hover {
        max-height: 0;
        overflow: hidden;
        transition: .5s ease-in-out max-height;

        & > * {
          display: none;
        }

      }
    }

    &:hover {
      .partner-logo {
        img {
          width: 35%;
        }
      }

      .d-on-hover {
        max-height: 1000px;

        & > * {
          display: block;
        }
      }

      .card-body {
        //justify-content: space-between;
      }
    }
  }
}

.section.section-university {
  .card {
    aspect-ratio: 1.5;

    .card-body {
      justify-content: center;
    }
  }
}

.section .projectslider {
  @include media-breakpoint-up(md) {
    .flickity-prev-next-button.next {
      top: 90%;
      right: 30%;
    }

    .flickity-prev-next-button.previous {
      top: 90%;
      left: 30%;
    }
  }

  .flickity-page-dots {
    left: 0;
  }

  .carousel-item {
    padding: 0 !important;
  }
}