@import "../../../../node_modules/bootstrap5/scss/bootstrap";
//@import "../../../../node_modules/bootstrap5/scss/functions";
//@import "../../../../node_modules/bootstrap5/scss/variables";
//@import "../../../../node_modules/bootstrap5/scss/mixins/breakpoints";

// override default bootstrap mediaquery with MQ SCSS
// https://github.com/sass-mq/sass-mq
@import "../../../general/scss/bootstrap-custom/breakpoints";
@import "../../../general/scss/bootstrap-custom/containers";

//@import "../../../../node_modules/bootstrap5/scss/mixins";
//@import "../../../../node_modules/bootstrap5/scss/root";
////@import "../../../../node_modules/bootstrap5/scss/print";
//@import "../../../../node_modules/bootstrap5/scss/reboot";
//@import "../../../../node_modules/bootstrap5/scss/type";
//@import "../../../../node_modules/bootstrap5/scss/images";
////@import "../../../../node_modules/bootstrap5/scss/code";
//@import "../../../../node_modules/bootstrap5/scss/grid";
//@import "../../../../node_modules/bootstrap5/scss/tables";
//@import "../../../../node_modules/bootstrap5/scss/forms";
//@import "../../../../node_modules/bootstrap5/scss/buttons";
//@import "../../../../node_modules/bootstrap5/scss/dropdown";
//// @import "../../../../node_modules/bootstrap5/scss/button-group";
//// @import "../../../../node_modules/bootstrap5/scss/input-group";
////@import "../../../../node_modules/bootstrap5/scss/custom-forms";
//@import "../../../../node_modules/bootstrap5/scss/nav";
//@import "../../../../node_modules/bootstrap5/scss/navbar";
//@import "../../../../node_modules/bootstrap5/scss/card";
//@import "../../../../node_modules/bootstrap5/scss/breadcrumb";
//@import "../../../../node_modules/bootstrap5/scss/pagination";
//// @import "../../../../node_modules/bootstrap5/scss/badge";
//// @import "../../../../node_modules/bootstrap5/scss/jumbotron";
//@import "../../../../node_modules/bootstrap5/scss/alert";
//// @import "../../../../node_modules/bootstrap5/scss/progress";
////@import "../../../../node_modules/bootstrap5/scss/media";
//// @import "../../../../node_modules/bootstrap5/scss/list-group";
//@import "../../../../node_modules/bootstrap5/scss/close";
//@import "../../../../node_modules/bootstrap5/scss/modal";
//@import "../../../../node_modules/bootstrap5/scss/tooltip";
//@import "../../../../node_modules/bootstrap5/scss/popover";
//// @import "../../../../node_modules/bootstrap5/scss/carousel";
//@import "../../../../node_modules/bootstrap5/scss/utilities";
//@import "../../../../node_modules/bootstrap5/scss/spinners";
//@import "../../../../node_modules/bootstrap5/scss/toasts";
//@import "../../../../node_modules/bootstrap5/scss/transitions";
//@import "../../../../node_modules/bootstrap5/scss/containers";

@import './bootstrap-custom/toasts';
@import './bootstrap-custom/tooltips';


.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.rounded-20 {
  border-radius: 20px!important;
}

.rounded-lg-20 {
  @include media-breakpoint-up(lg){
    border-radius: 20px!important;
  }
}