.newsletter {

  color: #3A3A3A;

  h2 {
    margin-top: 0;
    margin-bottom: 1.625rem;
    line-height: 1;
    color: #3A3A3A;

    &.head-1 {
      font: normal normal bold 35px/44px Source Sans Pro;
      letter-spacing: 0.7px;
    }

    &.head-2 {
      font: normal normal 600 15px/19px Source Sans Pro;
      letter-spacing: 0.3px;
      text-transform: uppercase;
    }
  }

  .btn, .form-control {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 0;
    font-size: 0.9375rem;
  }

  .btn {
    background-color: #3A3A3A;
    color: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .on-hover {
      visibility: hidden;
      padding: 0;
      overflow: hidden;
      text-align: center;
      transition: padding 300ms
    }

    &:hover {
      .on-hover {
        visibility: visible;
        padding: 0 0.6rem;
      }
    }
  }

  .form-control {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: auto;
  }

  a {
    color: #3A3A3A;

    &:hover {
      color: #000;
    }
  }

  @include mq($from: md) {
    padding: 25px 0 60px 0;

  }

  .mail-box {
    width: 100%;
    max-width: 650px;

    @include mq($from: lg) {
      width: 50%;
    }
  }

  .text-box {
    width: auto;

    @include mq($from: lg) {
      max-width: 50%;
    }
  }

  #mail-addr-form {
    input {
      height: 50px;
      max-width: 430px;
    }

    button {
      height: 50px;
    }
  }

  p {
    max-width: 430px;
  }
}