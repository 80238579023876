.navbar {
  padding: 0.3125rem 0;

  @include mq($from: md) {
    position: relative;
    padding: 0.5rem 60px;
  }

  @media (min-width: 1920px) {
    height: $navHeightUnder1920;
  }

  .mainAvatar {
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 auto;

    &__border {
      border: 3px solid white;
    }
  }


  .sidebar {
    .mainAvatar {
      width: 6rem;
      height: 6rem;
    }
  }
}

.navbar-collapse {
  //justify-content: flex-end;
  justify-content: space-between;
  @include media-breakpoint-up(md) {
    margin-left: 1rem;
  }
}

.user-nav {
  .nav-link {
    font-size: 17px;
    font-weight: 700;
    z-index: 3;
    position: relative;
  }

  &__avatar {
    color: #3a3a3a;
    font-size: 1.063rem;
    font-weight: 700;
    letter-spacing: 0.021rem;

    .ico {
      width: 2.5rem;
    }

    @include media-breakpoint-up(lg) {
      .ico {
        margin-left: -5px;
      }
    }

    svg {
      transition: all 300ms;
      margin-left: 20px;
      margin-right: 10px;
    }
  }

  &__avatar .nav-link {
    @include media-breakpoint-up(sm) {
      background: white;
    }
    z-index: 3;
  }

  .dropdown-menu {
    z-index: 2;
    text-align: left;
    top: 70%;
    border: 0;
    right: 15px;
    left: 25px;
    min-width: 150px;
    @include media-breakpoint-up(lg) {
      padding-top: 20px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    }
    @include media-breakpoint-down(sm) {
      background: #F8F9FA;
    }
  }

  .dropdown-item {
    color: #3a3a3a;
    font-size: 1.063rem;
    font-weight: 700;
    letter-spacing: 0.021rem;
    text-decoration: none;
    @include media-breakpoint-down(sm) {
      padding: 1.25rem 2.5rem;
      font-size: 1rem;
      font-weight: 300;
    }
    @include media-breakpoint-between(sm, md) {
      padding: 1.25rem 0;
      font-weight: 300;
    }

  }

  .show svg {
    transform: rotate(+180deg);
  }

  .show .nav-link {
    border-radius: 10px;
    @include media-breakpoint-up(lg) {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    }
  }

  #navbarDropdown {
    @include media-breakpoint-up('lg') {
      max-width: 300px;
    }

    div {
      flex: 0 0 auto;
    }

    span {
      flex: 1 1 auto;
      overflow-wrap: anywhere;
    }

    svg {
      flex: 0 0 auto;
    }
  }
}

.nav-item {
  padding: 0.45rem;
}

.navbar-brand {
  margin: 0;
  flex-grow: 1;

  img {
    display: block;
    max-width: 100%;
    height: auto;

    &.logo {
      width: 110px;
    }
  }

  @include mq($from: lg) {
    width: 112px;
    flex-grow: 0;
  }
}

.nav-link {
  font-size: 1.0625rem;
  font-weight: normal;
  text-decoration: none;
  color: #707070;
  font-family: "Source Sans Pro", sans-serif;

  &.active {
    font-weight: 600;
    color: #3A3A3A;
  }
}

.navbar-nav {
  margin-top: 1rem;
  align-items: center;

  @include mq($from: xxl) {
    margin-left: 120px;
    margin-top: 0;
  }
}

.user-nav {
  margin: unset;
  @include mq($until: md) {
    display: none;
  }
}

.mobile-menu-buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
  list-style: none;
  margin-bottom: 0;
  margin-right: 30px;
  @include mq($from: md) {
    display: none;
  }
}

@include mq($until: lg) {

  .navbar {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: nowrap;

    .navbar-left {
      width: 100%;
    }
  }

  .navbar.child-collapse {
    height: 100dvh;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    .navbar-left {
      padding: 0 0 0.3125rem 0;

      @include mq($from: md) {
        position: relative;
        padding: 0 60px 0.5rem 60px;
      }
    }
  }

  .navbar-nav {
    display: block;
    width: 100%;
  }

  .navbar-collapse {
    //position: absolute;
    //top: 100%;
    //left: -15px;
    //right: -15px;
    //height: calc(100vh - 160px);
    background: #F8F9FA;
    z-index: 100;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    margin: 1rem -1rem;
    width: 100vw;
    flex-basis: auto;

    h2 {
      display: block;
      margin: 0;
      padding: 1.25rem 2.5rem;
    }

    .nav-item {
      padding: 0;
    }

    .nav-link {
      display: block;
      padding: 1.25rem 2.5rem;
      font-size: 1rem;
    }
  }
}

@include mq($from: lg) {
  .navbar-overlay {
    display: none;
    position: fixed;
    z-index: 20;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    animation: fadeIn .5s;
  }
}

.navbar-toggler {
  border: none;
  background: transparent !important;
  outline: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: transparent !important;

    .icon-bar {
      color: black;
    }
  }

  .icon-bar {
    display: block;
    height: 2px;
    width: 22px;
    transition: all 0.2s;
    background-color: #000;
  }

  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  .icon-bar:nth-of-type(1) {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }

  .icon-bar:nth-of-type(2) {
    opacity: 0;
  }

  .icon-bar:nth-of-type(3) {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }

  &.collapsed {
    .icon-bar:nth-of-type(1) {
      transform: rotate(0);
    }

    .icon-bar:nth-of-type(2) {
      opacity: 1;
      filter: alpha(opacity=100);
    }

    .icon-bar:nth-of-type(3) {
      transform: rotate(0);
    }
  }
}

.code-button {
  background-color: #4773B1;
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 12px;
  line-height: 32px;
  padding-left: 10px;
  margin-right: 5px;

  .icon-doors {
    position: relative;
    top: 2px;
  }
}

.login-button {
  background-color: $primary;
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 12px;
  line-height: 32px;
  padding-left: 11px;
  margin-right: 5px;
}

.basket-button {
  background-color: $red;
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 12px;
  line-height: 32px;
  padding-left: 11px;
  margin-right: 5px;
  position: relative;

  .icon-basket {
    width: 88%;
    height: 95%;
    position: relative;
    left: -4px;
  }

  .basket-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    color: $red;
    background-color: white;
    border: 1px solid $red;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 17px;
    text-align: center;
    font-size: 12px;
    padding: 0;
  }
}

.user-bottom-nav {

}

#bs-canvas-user-menu {
  z-index: 2000;
}

.publication-card {

  .card {
    width: auto!important;
    height: auto;
    max-height: 245px;
    max-width: 453px;

    & > div {
      max-height: 100%;
    }

    .image {
      height: calc(100% - 4rem);
      max-width: 60%;
      margin: 0 2rem;

      img {
        height: 100%;
        max-width: 100%;
        aspect-ratio: 668/501;
      }
    }

    .sub-header {
      font-size: 12px;
      text-transform: uppercase;
      font-family: "Segoe UI", Arial, sans-serif;
    }

    .header {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      font-family: "Source Sans Pro", sans-serif;
    }

  }

  .card--link {
    padding-bottom: 15px;

    &:after {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      right: 12px;
      bottom: 12px;
      border-radius: 50%;
      background: url('data:image/svg+xml,<svg id="Arrow_Go_Icon" data-name="Arrow Go Icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"> <rect id="space" width="15" height="15" fill="%233a3a3a" opacity="0"/> <path id="Path_5033" data-name="Path 5033" d="M3685.775,565.366a.75.75,0,0,1-.585-1.219l3.225-4.031-3.225-4.031a.75.75,0,1,1,1.171-.937l3.6,4.5a.75.75,0,0,1,0,.937l-3.6,4.5A.748.748,0,0,1,3685.775,565.366Z" transform="translate(-3679.776 -552.616)" fill="%233a3a3a"/></svg>') no-repeat center white;
      //background-size: 35% 50%;
      transition: right .3s;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color .3s;
      border-radius: 20px;
    }

    &:hover {
      &:before {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &:after {
        right: 10px;
      }
    }
  }
}

.navbar-sub {
  @include mq($from: md) {
    padding-top: 30px!important;
  }
}

.nav-link[aria-expanded="true"] i svg {
  transform: rotate(180deg);
}

.nav-link.text-secondary {
  color:#B3B3B3!important;
}