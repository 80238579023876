.notice {
    max-width: 600px;
    margin: auto;
    background-color: $frog;

    .notice__head {
        h2 {
            margin: 0 0 1em 0;
            font-size: 1.5625rem;
        }
    }
}

